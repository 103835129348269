import { useEffect, useState } from "react";
import {
	Navigate,
	resolvePath,
	useNavigate,
	useParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import orderApi from "../../api/order";
import productApi from "../../api/product";
import { getItesForProcedure } from "../../utils/getItemsForProcedure";
import Review from "../RequestForms/Review";
import SelectDiagnosis from "../RequestForms/SelectDiagnosis";
import SelectProducts from "../RequestForms/SelectProducts";
import SelectQuantity from "../RequestForms/SelectQuantity";

const Stepps = ({ activeStep, onNext, onPrev, state, patient }) => {
	const navigate = useNavigate();
	const [items, setItems] = useState([]);
	const [procedurePrice, setProcedurePrice] = useState([]);
	const [selectedProcedure, setSelectedProcedure] = useState("");
	const [selectedDiagnosis, setSelectedDiagnosis] = useState("");
	const [itemsToRequest, setItemsToRequest] = useState([]);
	const [request, setRequest] = useState({
		orderedItems: itemsToRequest,
		diagnosis: "000000000",
		phoneNumber: "9786876",
		age: 30,
		sex: "male",
		orderTime: "09:30 am",
	});

	const params = useParams();

	useEffect(() => {
		productApi.getAll(onProductSuccess, onFaliure);
	}, []);

	const onProductSuccess = (resp) => {
		setItems(resp.data.items);
	};

	useEffect(() => {
		setItemsToRequest(getItesForProcedure(items, selectedProcedure));

		setRequest({
			...request,
			orderedItems: getItesForProcedure(items, selectedProcedure),
			diagnosis: selectedDiagnosis,
		});
	}, [selectedProcedure]);

	const handleAdd = (e) => {
		e.preventDefault();
		toast.promise(
			orderApi.update(
				{
					...request,
					orderDate: patient.orderDate,
					patientName: patient.patientName,
					procedure: selectedProcedure,
					appointmentId: params.appointmentId,
					procedurePrice: procedurePrice,
				},
				params.id,
				onSuccess,
				onFaliure
			),
			{
				pending: "Requesting ...",
				success: "",
				error: "",
			}
		);
	};

	const onSuccess = (res) => {
		setTimeout(() => navigate("/admin/orders"), 3000);
	};
	const onFaliure = (error) => {
		console.log("This went Off", error);
	};

	const handleChangeInItemQuantity = (e) => {
		let temp = [...request.orderedItems];

		let index = temp.findIndex((el) => el.item === e.target.name);

		let removed = temp.splice(index, 1);

		temp.push({
			...removed[0],
			quantity: parseInt(e.target.value),
		});

		setRequest({
			...request,
			orderedItems: temp,
		});
	};

	const selectItem = (id) => {
		let temp = [...itemsToRequest];

		let index = temp.findIndex((el) => el.item === id);

		if (temp[index].remainingQty < 1) {
			return toast.warning("The item you selected is not in stock");
		}

		let removed = index !== -1 && temp.splice(index, 1);

		temp.push({
			...removed[0],
			selected: true,
		});
		setRequest({
			...request,
			orderedItems: temp.filter((item) => item.selected),
			diagnosis: selectedDiagnosis,
		});

		setItemsToRequest(temp);
	};
	const deselectItem = (id) => {
		let temp = [...itemsToRequest];

		let index = temp.findIndex((el) => el.item === id);

		if (temp[index].remainingQty < 1) {
			return toast.warning("The item you selected is not in stock");
		}

		let removed = index !== -1 && temp.splice(index, 1);

		temp.push({
			...removed[0],
			selected: false,
		});

		setItemsToRequest(temp);
	};

	const handleProcedurePrice = (price) => {
		setProcedurePrice(parseInt(price));
	};
	return (
		<div>
			{activeStep === 0 ? (
				<SelectDiagnosis
					setSelectedProcedure={setSelectedProcedure}
					setSelectedDiagnosis={setSelectedDiagnosis}
					handleProcedurePrice={handleProcedurePrice}
					state={state}
					onNext={onNext}
					onPrev={onPrev}
				/>
			) : activeStep === 1 ? (
				<SelectProducts
					itemsToRequest={itemsToRequest}
					onNext={onNext}
					onPrev={onPrev}
					selectItem={selectItem}
					deselectItem={deselectItem}
				/>
			) : activeStep === 2 ? (
				<SelectQuantity
					itemsToRequest={itemsToRequest}
					onNext={onNext}
					onPrev={onPrev}
					handleChangeInItemQuantity={handleChangeInItemQuantity}
				/>
			) : (
				<Review
					itemsToRequest={itemsToRequest}
					onNext={handleAdd}
					onPrev={onPrev}
					request={request}
					patient={patient}
				/>
			)}
		</div>
	);
};

export default Stepps;
