import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import {
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	FormInput,
	FormSelect,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";
import diagnosisApi from "../../api/diagnosis";
import proceduresApi from "../../api/procedures";
import { diagnosisAdded } from "../../store/diagnosis-reducer";
import { proceduresAdded } from "../../store/procedure-reducer";

const SelectDiagnosis = ({
	title,
	setSelectedDiagnosis,
	setSelectedProcedure,
	state,
	onNext,
	onPrev,
	handleProcedurePrice,
}) => {
	const [procedures, setProcedures] = useState([]);
	const [diagnosis, setDiagnosis] = useState([]);

	const dispatch = useDispatch();
	useEffect(() => {
		proceduresApi.getAll(procedureOnSuccess, onFailure);
		diagnosisApi.getAll(diagnosisOnSuccess, onFailure);
		// eslint-disable-next-line
	}, []);

	const procedureOnSuccess = (data) => {
		dispatch(proceduresAdded(data.data.procedures));
		setProcedures(data.data.procedures);
	};
	const diagnosisOnSuccess = (data) => {
		dispatch(diagnosisAdded(data.data.allDiagnosis));
		setDiagnosis(data.data.allDiagnosis);
	};

	const onFailure = (error) => {
		console.log("This went Off ", error);
	};

	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>{title}</h6>
			</CardHeader>
			<ListGroup flush>
				<ListGroupItem className='p-3'>
					<Row>
						<Col>
							<Form
								method='post'
								onSubmit={(e) => {
									e.preventDefault();
									onNext();
								}}
							>
								<Row form>
									{/* First Name */}

									{/* Last Name */}

									<Col md='6' className='form-group'>
										<label htmlFor='feCategory'>Procedure</label>
										<FormSelect
											id='feCategory'
											required
											name='procedureUsedIn'
											onChange={(e) => setSelectedProcedure(e.target.value)}
										>
											<option selected='true' value='' disabled='disabled'>
												Procedures
											</option>
											{procedures.map((procedure) => (
												<option value={procedure._id}>
													{procedure.procedureName}
												</option>
											))}
										</FormSelect>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feCategory'>Diagnosis</label>
										<FormSelect
											id='feCategory'
											required
											name='procedureUsedIn'
											onChange={(e) => setSelectedDiagnosis(e.target.value)}
										>
											<option selected='true' value='' disabled='disabled'>
												Diagnosis
											</option>
											{diagnosis.map((diagnosis) => (
												<option value={diagnosis._id}>
													{diagnosis.diagnosisName}
												</option>
											))}
										</FormSelect>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feCategory'>Procedure Price</label>
										<FormInput
											id='feCategory'
											required
											type='number'
											min={1}
											name='procedurePrice'
											onChange={(e) => handleProcedurePrice(e.target.value)}
										></FormInput>
									</Col>
									<Col md='6' className='form-group'></Col>
									<Col md='6' className='form-group pt-3'>
										<Button disabled className='cust_buttons' theme='info'>
											Back
										</Button>
									</Col>
									<Col md='6' className='form-group pt-2'>
										<Button
											theme='success'
											type='submit'
											style={{ marginLeft: "80%" }}
											className='cust_buttons'
										>
											Next
										</Button>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

SelectDiagnosis.propTypes = {
	/**SelectDiagnosis
	 * The component's title.
	 */
	title: PropTypes.string,
};

SelectDiagnosis.defaultProps = {
	title: "Select Procedure and Diagnosis",
};

export default SelectDiagnosis;
