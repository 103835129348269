import { apiCall, apiFetch } from "./ApiCall";
const proceduresApi = {
	add: (data, onSuccess, onFailure) =>
		apiCall("post", data, "/procedure/", onSuccess, onFailure),
	getAll: (onSuccess, onFailure) =>
		apiFetch("get", "/procedure/", onSuccess, onFailure),
	deleteOne: (id, onSuccess, onFailure) =>
		apiFetch("delete", `/procedure/${id}`, onSuccess, onFailure),
	updateOne: (data, id, onSuccess, onFailure) =>
		apiCall("put", data, `/procedure/${id}`, onSuccess, onFailure),
};

export default proceduresApi;
