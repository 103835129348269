import React from "react";
import ProductDetail from "./views/ProductDetail";
// Layout Types
import { DefaultLayout } from "./layouts";
import NormalLayout from "./layouts/Normal";
// Route Views

import Activate from "./views/Activate";
import AdminOverview from "./views/AdminOverview";
// import Adminstrators from "./views/Adminstrators";
import Categories from "./views/Categories";
import ChangePassword from "./views/ChangePassword";
import CreateAdminProfile from "./views/CreateAdminProfile";
import EditCategory from "./views/EditCategory";
import EditProcedures from "./views/EditProceduers";
import EditProduct from "./views/EditProduct";
import EditSuppliers from "./views/EditSuppliers";
import ForgotPassword from "./views/ForgotPassword";
import LogIn from "./views/Login";
import Procedures from "./views/Procedures";
import Products from "./views/Products";
import UserProfile from "./views/Profile";
import RegisterCategory from "./views/RegisterCategory";
import RegisterProcedures from "./views/RegisterProceduers";
import RegisterProduct from "./views/RegisterProduct";
import RegisterSuppliers from "./views/RegisterSuppliers";
import ResetPassword from "./views/ResetPassword";
import Suppliers from "./views/Suppliers";
import Users from "./views/Users";
import Orders from "./views/Orders";
import RequestItem from "./views/Request-items";
import Appointments from "./views/Appointments";
import Diagnosis from "./views/ManageDiagnosis";
import UpdateOrder from "./views/UpdateOrder";
import UploadCVS from "./views/UploadCVS";
import SearchProducts from "./views/SearchProducts";
import Store from "./views/Store";

const routes = [
	{
		path: "/",
		exact: true,
		layout: NormalLayout,
		component: () => <LogIn />,
	},
	{
		path: "/user/forgot-password",
		exact: true,
		layout: NormalLayout,
		component: () => <ForgotPassword />,
	},
	{
		path: "/admin/profile",
		layout: DefaultLayout,
		component: UserProfile,
	},
	{
		path: "/user/register",
		layout: NormalLayout,
		component: CreateAdminProfile,
	},
	{
		path: "/user/reset/:id",
		exact: true,
		layout: NormalLayout,
		component: () => <ResetPassword />,
	},
	{
		path: "/admin/change-password/",
		exact: true,
		layout: NormalLayout,
		component: () => <ChangePassword />,
	},
	{
		path: "/user/activate-account/:id",
		exact: false,
		layout: NormalLayout,
		component: () => <Activate />,
	},
	{
		path: "/admin/overview",
		layout: DefaultLayout,
		component: AdminOverview,
	},
	{
		path: "/admin/register-product",
		layout: DefaultLayout,
		component: RegisterProduct,
	},
	{
		path: "/admin/upload-product",
		layout: DefaultLayout,
		component: UploadCVS,
	},
	{
		path: "/admin/products",
		layout: DefaultLayout,
		component: Products,
	},
	{
		path: "/admin/store",
		layout: DefaultLayout,
		component: Store,
	},
	{
		path: "/admin/search-product",
		layout: DefaultLayout,
		component: SearchProducts,
	},
	{
		path: "/admin/orders",
		layout: DefaultLayout,
		component: Orders,
	},
	{
		path: "/admin/request-items/:id",
		layout: DefaultLayout,
		component: RequestItem,
	},
	{
		path: "/admin/update-order/:id/:appointmentId",
		layout: DefaultLayout,
		component: UpdateOrder,
	},
	{
		path: "/admin/edit-product/:id",
		layout: DefaultLayout,
		component: EditProduct,
	},
	{
		path: "/admin/product-detail/:id",
		layout: DefaultLayout,
		component: ProductDetail,
	},
	{
		path: "/admin/create-admin-profile",
		layout: DefaultLayout,
		component: CreateAdminProfile,
	},
	{
		path: "/admin/register-supplier",
		layout: DefaultLayout,
		component: RegisterSuppliers,
	},
	{
		path: "/admin/suppliers",
		layout: DefaultLayout,
		component: Suppliers,
	},
	{
		path: "/admin/edit-supplier/:id",
		layout: DefaultLayout,
		component: EditSuppliers,
	},
	{
		path: "/admin/register-category",
		layout: DefaultLayout,
		component: RegisterCategory,
	},
	{
		path: "/admin/categories",
		layout: DefaultLayout,
		component: Categories,
	},
	{
		path: "/admin/edit-category/:id",
		layout: DefaultLayout,
		component: EditCategory,
	},
	{
		path: "/admin/register-procedure",
		layout: DefaultLayout,
		component: RegisterProcedures,
	},
	{
		path: "/admin/procedures",
		layout: DefaultLayout,
		component: Procedures,
	},
	{
		path: "/admin/diagnosis",
		layout: DefaultLayout,
		component: Diagnosis,
	},
	{
		path: "/admin/edit-procedure/:id",
		layout: DefaultLayout,
		component: EditProcedures,
	},
	{
		path: "/admin/users",
		layout: DefaultLayout,
		component: Users,
	},
	{
		path: "/admin/appointments",
		layout: DefaultLayout,
		component: Appointments,
	},
];
export default routes;
