import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "shards-react";
import proceduresApi from "../api/procedures";
import PageTitle from "../components/common/PageTitle";
import ProcedureForm from "../components/ProcedureForm/AddProcedureForm";

const RegisterProcedures = () => {
	const [data, setData] = useState({
		procedureName: "",
		serialNumber: "",
	});

	const navigate = useNavigate();

	const handleChange = (event) => {
		const { name, value } = event.target;

		let temp = { ...data };
		temp[name] = value;
		setData(temp);
	};
	const handleSubmit = () => {
		toast.promise(proceduresApi.add(data, onSuccess, onFailure), {
			pending: "Adding Procedure....",
		});
	};

	const onFailure = (error) => {
		console.log("This went Off", error);
	};

	const onSuccess = () => {
		setTimeout(() => {
			navigate(-1);
		}, 2000);
	};

	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<ToastContainer />
			<Row noGutters className='page-header py-4'>
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to='/admin/procedures'>Procedures</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>
						<PageTitle
							title=''
							subtitle='Register Procedure'
							md='12'
							className='ml-sm-auto mr-sm-auto'
						/>
					</BreadcrumbItem>
				</Breadcrumb>
			</Row>

			<Col lg='12'>
				<ProcedureForm
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					state={data}
				/>
			</Col>
		</Container>
	);
};

export default RegisterProcedures;
