import React, { useState } from "react";
import {
	Button,
	Col,
	FormInput,
	FormSelect,
	Modal,
	ModalBody,
	ModalFooter,
} from "shards-react";
import productApi from "../../api/product";

const FilterModal = ({ open, toggle, onFilter, setLoading }) => {
	const [filter, setFilter] = useState("date");
	const [query, setQuery] = useState("");

	const onSubmit = () => {
		toggle();
		setLoading(true);
		productApi.filter(
			query,
			(data) => {
				onFilter([data.data[0]]);
			},
			(error) => {
				console.log("This went Off", error);
			}
		);
	};

	return (
		<div>
			<Modal size='md' open={open} centered={true}>
				<ModalBody style={{ paddingTop: "70px" }}>
					<Col md='12' className='form-group' style={{ display: "flex" }}>
						<label
							htmlFor='feCategory'
							style={{ width: "130px", paddingTop: "8px" }}
						>
							Filter BY
						</label>
						<FormSelect
							id='feCategory'
							required
							name='procedureUsedIn'
							onChange={(e) => setFilter(e.target.value)}
						>
							<option value='Date'>Delivery Date</option>
							<option value='ItemCode'> Item Code</option>
							<option value='SerialNumber'>Serial Number</option>
							<option value='Category'>Category</option>
							<option value='Supplier'>Supplier</option>
							<option value='Procedure'>Procedure</option>
						</FormSelect>
					</Col>

					<Col md='12' className='form-group' style={{ display: "flex" }}>
						<label
							htmlFor='feCategory'
							style={{ width: "130px", paddingTop: "8px" }}
						>
							{filter}
						</label>
						<FormInput
							id='feCategory'
							required
							name={filter.charAt(0).toLowerCase() + filter.slice(1)}
							type={filter.toLowerCase() === "date" ? "date" : "text"}
							onChange={(e) =>
								setQuery(
									`${filter.charAt(0).toLowerCase() + filter.slice(1)}?${
										e.target.value
									}`
								)
							}
						/>
					</Col>
				</ModalBody>

				<ModalFooter>
					<Button theme='success' onClick={onSubmit} disabled={!query.length}>
						Filter
					</Button>
					<Button
						theme='danger'
						onClick={toggle}
						style={{ marginLeft: "20px" }}
					>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default FilterModal;
