import { apiCall, apiFetch } from "./ApiCall";
const categoriesApi = {
	add: (data, onSuccess, onFailure) =>
		apiCall("post", data, "/category/", onSuccess, onFailure),
	getAll: (onSuccess, onFailure) =>
		apiFetch("get", "/category/", onSuccess, onFailure),
	deleteOne: (id, onSuccess, onFailure) =>
		apiFetch("delete", `/category/${id}`, onSuccess, onFailure),
	updateOne: (data, id, onSuccess, onFailure) =>
		apiCall("put", data, `/category/${id}`, onSuccess, onFailure),
};

export default categoriesApi;
