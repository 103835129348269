import PropTypes from "prop-types";
import React, { useState } from "react";

import "react-phone-number-input/style.css";
import {
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	FormInput,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";
import productApi from "../../api/product";

const UploadCVSForm = ({ title }) => {
	const [file, setFile] = useState(null);
	const onSubmit = () => {
		const formData = new FormData();
		formData.append("uploadfile", file);
		productApi.upladcvs(formData, onSuccess, onFailure);
	};

	const onSuccess = (data) => {
		console.log(data);
	};
	const onFailure = (error) => {
		console.log("This went Off ", error);
	};

	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>{title}</h6>
			</CardHeader>
			<ListGroup flush>
				<ListGroupItem className='p-3'>
					<Row>
						<Col>
							<Form
								method='post'
								onSubmit={(e) => {
									e.preventDefault();
									onSubmit();
								}}
							>
								<Row form>
									{/* First Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>CVS File</label>
										<FormInput
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											type='file'
											accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
											required
											onChange={(e) => setFile(e.target.files[0])}
										/>
									</Col>

									{/* Password */}
								</Row>

								<Button theme='accent' type='submit'>
									Upload
								</Button>
							</Form>
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

UploadCVSForm.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

UploadCVSForm.defaultProps = {
	title: "Upload Cvs",
};

export default UploadCVSForm;
