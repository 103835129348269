import { apiCall, apiFetch } from "./ApiCall";
const suppliersApi = {
	add: (data, onSuccess, onFailure) =>
		apiCall("post", data, "/supplier/", onSuccess, onFailure),
	getAll: (onSuccess, onFailure) =>
		apiFetch("get", "/supplier/", onSuccess, onFailure),
	deleteOne: (id, onSuccess, onFailure) =>
		apiFetch("delete", `/supplier/${id}`, onSuccess, onFailure),
	updateOne: (data, id, onSuccess, onFailure) =>
		apiCall("put", data, `/supplier/${id}`, onSuccess, onFailure),
};

export default suppliersApi;
