import PropTypes from "prop-types";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	FormInput,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";
import diagnosisApi from "../../api/diagnosis";
// import departmentsApi from "../../api/departments";

const CreateDiagnosis = ({ fetch, setFetch }) => {
	const [diagnosisName, setDiagnosis] = useState("");
	const [serialNumber, setsSerialNumber] = useState("");
	const [loading, setLoading] = useState(false);
	const handleSubmit = () => {
		setLoading(false);
		toast.promise(
			diagnosisApi.add(
				{
					diagnosisName: diagnosisName,
					serialNumber: serialNumber,
				},
				onSuccess,
				onFaileure
			),
			{
				pending: "proccessing ...",
			}
		);
	};

	const onSuccess = () => {
		setLoading(false);
		setFetch(!fetch);
		setDiagnosis("");
		setsSerialNumber("");
	};
	const onFaileure = (error) => {
		console.log(error);
		setLoading(false);
	};

	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>Create Diagnosis</h6>
			</CardHeader>
			<ListGroup flush>
				<ListGroupItem className='p-3'>
					<Row style={{ padding: "30px 0", paddingBottom: 0 }}>
						<Col>
							<Form
								method='post'
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
							>
								<Row form>
									<Col md='7' className='form-group'>
										<FormInput
											md='7'
											requ
											id='feDiagnosis'
											placeholder='Diagnosis Name'
											name='deptname'
											value={diagnosisName}
											onChange={(e) => setDiagnosis(e.target.value)}
											required
										/>
									</Col>
									<Col md='7' className='form-group'>
										<FormInput
											md='6'
											requ
											id='feDiagnosis'
											placeholder='serialNumber'
											name='serialNumber'
											value={serialNumber}
											onChange={(e) => setsSerialNumber(e.target.value)}
											required
										/>
									</Col>
									<Col md='12' className='form-group'>
										<Button theme='accent' disabled={loading} type='submit'>
											Create Diagnosis
										</Button>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

CreateDiagnosis.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

CreateDiagnosis.defaultProps = {
	title: "Account Details",
};

export default CreateDiagnosis;
