import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row } from "shards-react";
import diagnosisApi from "../api/diagnosis";
import PageTitle from "../components/common/PageTitle";
import CreateDiagnosis from "../components/diagnosis/CreateDiagnosis";
import EditDiagnosis from "../components/EditDiagnosis/EditDiagnosis.component";
import Featching from "../components/Featching/featching";

const Diagnosis = () => {
	const columns = [
		{
			title: "Diagnosis Name",
			field: "diagnosisName",
		},
		{
			title: "Serial Number",
			field: "serialNumber",
		},
	];
	const [data, setData] = useState([]);
	const [openEditor, setOpenEditor] = useState(false);
	const [id, setId] = useState("");
	const [fetch, setFetch] = useState(false);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		diagnosisApi.getAll(fetchSuccess, onFaliure);
		// eslint-disable-next-line
	}, [fetch]);

	const onFaliure = (error) => {
		toast.error(error.message);
	};
	const fetchSuccess = (res) => {
		setLoading(false);

		// dispatch(departmentsAdded(res.data.departments));
		setData(res.data.allDiagnosis);
	};

	const handleDelete = (id) => {
		setFetch(!fetch);
		diagnosisApi.deleteOne(
			id,
			() => toast.success("Diagnosis deleted successfully!"),
			onFaliure
		);
	};

	return (
		<Container fluid className='main-content-container px-4'>
			{/* Page Header */}
			<Row noGutters className='page-header py-4'>
				<PageTitle sm='4' title='Diagnosiss' className='text-sm-left' />
			</Row>

			<ToastContainer />
			<EditDiagnosis
				open={openEditor}
				toggle={() => setOpenEditor(!openEditor)}
				close={() => setOpenEditor(false)}
				data={id}
				setFetch={setFetch}
				fetch={fetch}
			/>
			<CreateDiagnosis setFetch={setFetch} fetch={fetch} />
			<Row>
				<Col>
					<Card small className='mb-4 overflow-hidden'>
						<CardHeader
							className='bg-dark'
							style={{
								display: "flex",
								justifyContent: "space-between",
								zIndex: 99,
							}}
						>
							<h6 className='m-0 text-white'>All Diagnosiss</h6>
						</CardHeader>
						<CardBody className='bg-light p-0 table__body'>
							<MaterialTable
								title={""}
								options={{
									exportButton: true,
									actionsColumnIndex: -1,
									search: true,
								}}
								columns={columns}
								data={data}
								editable={{
									onRowDelete: (oldData) =>
										new Promise((resolve, reject) => {
											setTimeout(() => {
												handleDelete(oldData._id);
												resolve();
											}, 1000);
										}),
								}}
								actions={[
									{
										icon: "edit",
										tooltip: "Save User",
										onClick: (event, rowData) => {
											setOpenEditor(true);
											setId(rowData);
										},
									},
								]}
							/>
							{loading && <Featching />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Diagnosis;
