import PropTypes from "prop-types";
import React from "react";

import "react-phone-number-input/style.css";
import {
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";

const Review = ({ handleSubmit, onNext, onPrev, request, patient }) => {
	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>Review Request</h6>
			</CardHeader>
			<ListGroup flush>
				<ListGroupItem className='p-3'>
					<Row>
						<Col>
							<Form
								method='post'
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
								style={{ fontSize: "16px", fontWeight: 200 }}
							>
								<p style={{ marginBottom: "5px" }}>
									Patient Name : {patient.patientName}
								</p>
								<p style={{ marginBottom: "5px" }}>
									Appointment Date : {patient.apptDate}
								</p>
								<p style={{ marginBottom: "5px" }}>
									Appointment Time : {patient.apptTime}
								</p>
								<p style={{ marginBottom: "25px" }}>
									Reason to visit : {patient.reason}
								</p>
								<h5>Items</h5>
								<hr />
								<Row form>
									{request &&
										request.orderedItems.map((item) => (
											<Col
												md='3'
												style={{
													listStyle: "number",
													padding: "30px 50px",
													paddingTop: "10px",
													fontSize: "16px",
													fontWeight: 100,
												}}
											>
												<li>
													Name: {item.itemName} <br /> Size : {item.size}
													<br />
													Quantity : {item.quantity}
												</li>
											</Col>
										))}
									<Col md='10' className='form-group pt-3'>
										<Button theme='info' onClick={onPrev}>
											Back
										</Button>
									</Col>
									<Col md='2' className='form-group pt-2'>
										<Button theme='success' type='submit' onClick={onNext}>
											Request Items
										</Button>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

Review.propTypes = {
	/**SelectDiagnosis
	 * The component's title.
	 */
	title: PropTypes.string,
};

Review.defaultProps = {
	title: "Request Product",
};

export default Review;
