import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Row,
} from "shards-react";
import proceduresApi from "../api/procedures";
import productApi from "../api/product";
import PageTitle from "../components/common/PageTitle";
import { proceduresAdded } from "../store/procedure-reducer";

import MaterialTable from "material-table";
import React from "react";
import { productsAdded } from "../store/products-reducer";

import { formatItme } from "../utils/formatResponse";
import Featching from "../components/Featching/featching";
import UpdateItemQuantity from "../components/UpdateItemQuantity/UpdateItemQuantity.component";
import FilterModal from "../components/FilterModal/FIlterModal.component";

const SearchProducts = () => {
	const [data, setData] = useState([]);
	const [product, setProduct] = useState("");
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [openFilter, setOpenFilter] = useState(false);
	const columns = [
		{ title: "Name", field: "itemName" },
		{
			title: "Expiry Date",
			field: "expiryDate",
		},
		{ title: "Procedure Used In", field: "procedureUsedIn" },
		{ title: "Price", field: "sellingPrice" },
		{
			title: "Quantity in stock",
			field: "stock",
			editable: false,
			render: (rowData) => (
				<Button
					style={{ width: "100%" }}
					theme={
						rowData.stock < 5
							? "danger"
							: rowData.stock < 10
							? "warning"
							: "none"
					}
				>
					{rowData.stock}
				</Button>
			),
		},
		{
			title: "Add to Stock",
			field: "request",
			editable: false,
			render: (rowData) => (
				<Button
					theme='success'
					onClick={() => {
						// navigate(`/admin/request-items/${rowData._id}`);
						setProduct(rowData);
						setOpen(true);
					}}
				>
					Add to stock
				</Button>
			),
		},
	];
	const procedures = useSelector((state) => state.procedures);
	const [products, setProducts] = useState(
		useSelector((state) => state.products)
	);
	const dispatch = useDispatch();
	const [fetch, setFetch] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		productApi.getAll(onSuccess, onFailure);
		setData(formatItme(products, procedures));
		// eslint-disable-next-line
	}, [fetch]);

	const handleDelete = (id) => {
		productApi.deleteOne(
			id,
			() => {
				toast.success("Item Deleted!");
				setFetch(!fetch);
				setLoading(true);
			},
			onFailure
		);
	};

	const onSuccess = (data) => {
		proceduresApi.getAll(procedureSuccess, onFailure);
		setProducts(data.data.items);

		dispatch(productsAdded(data.data.items));
	};
	const procedureSuccess = (data) => {
		setLoading(false);
		dispatch(proceduresAdded(data.data.procedures));

		setData(formatItme(products, data.data.procedures));
	};
	const onFailure = (error) => {
		dispatch(productsAdded([]));

		console.log("This went Off ", error);
	};

	return (
		<Container fluid className='main-content-container px-4'>
			{/* Page Header */}
			<Row noGutters className='page-header py-4'>
				<PageTitle
					sm='4'
					title='Find product by date'
					className='text-sm-left'
				/>
			</Row>
			<FilterModal
				open={openFilter}
				toggle={() => setOpenFilter(!openFilter)}
			/>
			<ToastContainer />
			<UpdateItemQuantity
				open={open}
				toggle={() => setOpen(!open)}
				item={product}
				setFetch={setFetch}
				fetch={fetch}
			/>
			<Row>
				<Col>
					<Card small className='mb-4 overflow-hidden'>
						<CardHeader
							className='bg-dark'
							style={{
								display: "flex",
								justifyContent: "space-between",
								zIndex: 99999999999999999,
							}}
						>
							<h6 className='m-0 text-white'>All Products</h6>
							<div>
								<Button
									theme='success'
									style={{ marginRight: "10px" }}
									onClick={() => {
										navigate("/admin/register-product");
									}}
								>
									<span style={{ marginRight: "10px" }}>
										<i className='material-icons'>add</i>
									</span>
									Add New Product
								</Button>
								<Button
									theme='success'
									onClick={() => {
										navigate("/admin/upload-product");
									}}
								>
									<span style={{ marginRight: "10px" }}>
										<i className='material-icons'>folder</i>
									</span>
									Upload CVS
								</Button>
							</div>
						</CardHeader>
						<CardBody className='bg-light p-0 pb-0'>
							<MaterialTable
								title={""}
								options={{
									exportButton: true,
									actionsColumnIndex: -1,
									search: true,
								}}
								columns={columns}
								data={data}
								editable={{
									onRowDelete: (oldData) =>
										new Promise((resolve, reject) => {
											setTimeout(() => {
												handleDelete(oldData._id);
												resolve();
											}, 1000);
										}),
								}}
								actions={[
									{
										icon: "book",
										tooltip: "View Detail",
										onClick: (event, rowData) => {
											navigate(`/admin/product-detail/${rowData._id}`);
										},
									},
									{
										icon: "edit",
										tooltip: "Edit Product",
										onClick: (event, rowData) => {
											navigate(`/admin/edit-product/${rowData._id}`);
										},
									},
								]}
							/>
							{loading && <Featching />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default SearchProducts;
