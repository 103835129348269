import MaterialTable from "material-table";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Row,
} from "shards-react";
import productApi from "../api/product";
import PageTitle from "../components/common/PageTitle";
import Featching from "../components/Featching/featching";
import { suppliersAdded } from "../store/supplier-reducer";

const Store = () => {
	const [data, setData] = useState();
	const columns = [
		{ title: "Item Code", field: "itemCode" },
		{
			title: "Delivered Qty",
			field: "deliveredQty",
		},
		{ title: "Used Qty", field: "usedQty" },
		{ title: "Remaining Qty", field: "remainingQty" },
	];
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	useEffect(() => {
		productApi.getInventory(onSuccess, onFailure);
		// eslint-disable-next-line
	}, []);

	const onSuccess = (resp) => {
		setData(resp.data);
		console.log(resp.data);
		setLoading(false);
	};
	const onFailure = (error) => {
		dispatch(suppliersAdded([]));

		console.log("This went Off ", error);
	};

	return (
		<Container fluid className='main-content-container px-4'>
			{/* Page Header */}
			<Row noGutters className='page-header py-4'>
				<PageTitle sm='4' title='Suppliers' className='text-sm-left' />
			</Row>
			<ToastContainer />

			<Row>
				<Col>
					<Card small className='mb-4 overflow-hidden'>
						<CardHeader
							className='bg-dark'
							style={{
								display: "flex",
								justifyContent: "space-between",
								zIndex: 99,
							}}
						>
							<h6 className='m-0 text-white'>All Suppliers</h6>
							<Button
								theme='success'
								onClick={() => {
									navigate("/admin/register-supplier");
								}}
							>
								<span style={{ marginRight: "10px" }}>
									<i className='material-icons'>add</i>
								</span>
								Add New Supplier
							</Button>
						</CardHeader>
						<CardBody className='bg-light p-0 '>
							<MaterialTable
								title={""}
								options={{
									exportButton: true,
									actionsColumnIndex: -1,
									search: true,
								}}
								columns={columns}
								data={data}
							/>
							{loading && <Featching />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Store;
