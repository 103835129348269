import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "shards-react";
import productApi from "../api/product";
import PageTitle from "../components/common/PageTitle";

import ProductDetailView from "../components/ProductForm/ProductDetailView";
import { removeKey } from "../utils/deleteKeys";

const ProductDetail = () => {
	const params = useParams();
	const [product, setProduct] = useState(
		useSelector((state) =>
			state.products.find((product) => product._id === params.id)
		)
	);

	const navigate = useNavigate();

	const handleChange = (event) => {
		const { name, value } = event.target;
		let temp = { ...product };
		temp[name] = value;
		setProduct(temp);
	};
	useEffect(() => {
		productApi.getOne(params.id, onFetchSuccess, onFailure);
	}, []);

	const handleSubmit = () => {
		toast.promise(
			productApi.updateOne(
				removeKey(product),
				product._id,
				onSuccess,
				onFailure
			),
			{
				pending: "Saving Product....",
			}
		);
	};

	const onFailure = (error) => {
		console.log("This went Off ", error);
	};

	const onSuccess = () => {
		setTimeout(() => {
			navigate(-1);
		}, 2000);
	};
	const onFetchSuccess = (resp) => {
		setProduct(resp.data.item);
	};

	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<ToastContainer />
			<Row noGutters className='page-header py-4'>
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to='/admin/products'>Products</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>
						<PageTitle
							title=''
							subtitle='Edit Product'
							md='12'
							className='ml-sm-auto mr-sm-auto'
						/>
					</BreadcrumbItem>
				</Breadcrumb>
			</Row>

			<Col lg='12'>
				{product && (
					<ProductDetailView
						handleChange={handleChange}
						handleSubmit={handleSubmit}
						state={product}
					/>
				)}
			</Col>
		</Container>
	);
};

export default ProductDetail;
