import React, { useEffect } from "react";
import { Nav } from "shards-react";

import UserActions from "./UserActions";

import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line
import diagnosisApi from "../../../../api/diagnosis";
import orderApi from "../../../../api/order";
import proceduresApi from "../../../../api/procedures";
import productApi from "../../../../api/product";
import { diagnosisAdded } from "../../../../store/diagnosis-reducer";
import { ordersAdded } from "../../../../store/orders-reducer";
import { proceduresAdded } from "../../../../store/procedure-reducer";
import { productsAdded } from "../../../../store/products-reducer";
import { userSignedOut } from "../../../../store/user-reducer";

const Func = () => {
	const user = useSelector((state) => state.user[0]);
	const dispatch = useDispatch();

	const onLogout = () => {
		sessionStorage.clear();
		dispatch(userSignedOut([]));
		dispatch({ type: "RESET_APP", payload: [] });

		// userApi.logout();
	};

	useEffect(() => {
		diagnosisApi.getAll(onDiagnosisSuccess, onFailure);
		productApi.getAll(onProductSuccess, onFailure);
		proceduresApi.getAll(onProcedureSuccess, onFailure);
		orderApi.getAll(onOrderSuccess, onFailure);
		// eslint-disable-next-line
	}, [fetch]);

	const onOrderSuccess = (resp) => {
		dispatch(ordersAdded(resp.data.orders));
	};
	const onProcedureSuccess = (resp) => {
		// setProcedures(resp.data.procedures);
		dispatch(proceduresAdded(resp.data.procedures));
	};
	const onDiagnosisSuccess = (resp) => {
		dispatch(diagnosisAdded(resp.data.allDiagnosis));
	};
	const onProductSuccess = (resp) => {
		dispatch(productsAdded(resp.data.items));
	};

	const onFailure = (error) => {
		// dispatch(productsAdded([]));
		console.log("This went Off ", error);
	};

	return (
		<Nav navbar className='border-left flex-row'>
			{/* <Notifications /> */}
			<UserActions onLogout={onLogout} user={user} />
		</Nav>
	);
};
export default Func;
