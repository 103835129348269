import React, { useState } from "react";
import {
	Button,
	FormInput,
	InputGroup,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "shards-react";
import productApi from "../../api/product";
import "./UpdateItemQuantity.styles.css";
const UpdateItemQuantity = ({ toggle, open, item, setFetch, fetch }) => {
	const [quantity, setQuantity] = useState(0);

	const handleAdd = (e) => {
		e.preventDefault();

		productApi.updateProductQuantity(
			{
				transactionType: "add",
				amount: quantity,
			},
			item._id,
			onSuccess,
			onFaliure
		);
	};

	const onSuccess = (res) => {
		setFetch(!fetch);
		toggle();
	};
	const onFaliure = (error) => {
		console.log("This went Off", error);
	};
	console.log("this is the itme ", item);
	return (
		<Modal
			open={open}
			toggle={toggle}
			centered={true}
			size='md'
			style={{
				zIndex: "99999",
			}}
		>
			<ModalHeader>Update Quantity for {item.itemName} </ModalHeader>

			<form onSubmit={handleAdd}>
				<ModalBody style={{ height: "fit-content" }}>
					<InputGroup className='time-modal'>
						<label htmlFor='start' style={{ padding: "10px 20px" }}>
							increase quantity by:
						</label>
						<FormInput
							placeholder='Quantity'
							type='number'
							className='time-selector'
							id='start'
							required
							min={0}
							value={quantity}
							onChange={(e) => {
								setQuantity(parseInt(e.target.value));
							}}
						/>

						{/* </FormInput> */}
					</InputGroup>
					{/* <InputGroup className='time-modal'>
						<label htmlFor='start' style={{ padding: "10px 20px" }}>
							Procedure
						</label>
						<FormSelect
							placeholder='time'
							type='time'
							className='time-selector'
							id='start'
							required
							value={selectedProcedure}
							onChange={(e) => setSelectedProcedure(e.target.value)}
						>
							<option value='' disabled>
								{" "}
								Select Procedure
							</option>
							{procedures.map((procedure) => (
								<option value={procedure._id}>{procedure.procedureName}</option>
							))}
						</FormSelect>
					</InputGroup> */}
				</ModalBody>
				<ModalFooter>
					<Button
						theme='success'
						style={{ zIndex: "99999999999" }}
						type='submit'
						// disabled={!itemsToRequest.length}
					>
						Update
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default UpdateItemQuantity;
