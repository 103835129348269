import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	FormInput,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";
import proceduresApi from "../../api/procedures";
import { proceduresAdded } from "../../store/procedure-reducer";

const RequestProduct = ({ title, handleChange, handleSubmit, state }) => {
	const [procedures, setProcedures] = useState([]);

	const dispatch = useDispatch();
	useEffect(() => {
		proceduresApi.getAll(procedureOnSuccess, onFailure);
		// eslint-disable-next-line
	}, []);

	const procedureOnSuccess = (data) => {
		dispatch(proceduresAdded(data.data.procedures));
		let temp = [];
		data.data.procedures.map((e) =>
			temp.push({ value: e._id, label: e.procedureName })
		);
		setProcedures(temp);
	};

	const onFailure = (error) => {
		console.log("This went Off ", error);
	};

	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>{title}</h6>
			</CardHeader>
			<ListGroup flush>
				<ListGroupItem className='p-3'>
					<Row>
						<Col>
							<Form
								method='post'
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
							>
								<Row form>
									{/* First Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Product Name</label>
										<FormInput
											id='feProductName'
											placeholder='Patient Name'
											name='patientName'
											// value={state.name}
											required
											onChange={handleChange}
										/>
									</Col>

									{/* Last Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='fePurcahePrice'>Age</label>
										<FormInput
											id='fePurchasePrice'
											placeholder='Age'
											name='age'
											required
											type='number'
											onChange={handleChange}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feSerial'>sex</label>
										<FormInput
											type='text'
											required
											id='feSerial'
											placeholder='Sex'
											name='sex'
											onChange={handleChange}
										/>
									</Col>

									<Col md='6' className='form-group'>
										<label htmlFor='feCategory'>Procedure</label>

										<Select
											// defaultValue={[colourOptions[2], colourOptions[3]]}
											// isMulti
											onChange={(newValue, actionMeta) => {
												let temp = [];

												newValue.map((data) => {
													temp.push(data.value);
												});

												handleChange({
													target: { name: "proceduresUsedIn", value: temp },
												});
											}}
											name='colors'
											options={procedures}
											className='basic-multi-select'
											classNamePrefix='select'
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feDeliveryDate'>Diagnosis</label>
										<FormInput
											type='text'
											required
											id='feDelivery'
											name='diagnosis'
											onChange={handleChange}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feDeliveryDate'>Date</label>
										<FormInput
											type='date'
											required
											id='feDelivery'
											name='date'
											onChange={handleChange}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feDeliveryDate'>time</label>
										<FormInput
											type='time'
											required
											id='feDelivery'
											// placeholder=''
											name='time'
											onChange={handleChange}
										/>
									</Col>
								</Row>

								<Button theme='accent' disabled={state.loading} type='submit'>
									Make a request
								</Button>
							</Form>
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

RequestProduct.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

RequestProduct.defaultProps = {
	title: "Request Product",
};

export default RequestProduct;
