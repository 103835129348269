import PropTypes from "prop-types";
import React from "react";

import "react-phone-number-input/style.css";
import {
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	FormInput,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";

const EditProcedureForm = ({ title, handleChange, handleSubmit, state }) => {
	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>{title}</h6>
			</CardHeader>
			<ListGroup flush>
				<ListGroupItem className='p-3'>
					<Row>
						<Col>
							<Form>
								<Row form>
									{/* First Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='feProcedureName'>Procedure Name</label>
										<FormInput
											id='feProcedureName'
											placeholder='Procedure Name'
											name='procedureName'
											value={state.procedureName}
											onChange={handleChange}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feProcedureName'>Serial Number</label>
										<FormInput
											id='feProcedureName'
											placeholder='Procedure Name'
											name='serialNumber'
											value={state.serialNumber}
											onChange={handleChange}
										/>
									</Col>
									{/* Last Name */}

									{/* Password */}
								</Row>

								<Button theme='accent' onClick={handleSubmit}>
									Save Procedure
								</Button>
							</Form>
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

EditProcedureForm.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

EditProcedureForm.defaultProps = {
	title: "Procedure Details",
};

export default EditProcedureForm;
