import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "diagnosis",
	initialState: [],
	reducers: {
		diagnosisAdded: (diagnosis, action) => {
			return action.payload;
		},
	},
});

export const { diagnosisAdded } = slice.actions;

export default slice.reducer;
