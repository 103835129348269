import { testModeAPI } from "react-ga";

export const removeKey = (data) => {
	let temp = { ...data };
	delete temp.createdAt;
	delete temp.updatedAt;
	delete temp.__v;
	delete temp._id;
	delete temp.deliveredTotal;
	return temp;
};

export const formatItemsToUpdate = (data) => {
	let temp = { ...data };
	delete temp.createdAt;
	delete temp.updatedAt;
	delete temp.__v;
	delete temp._id;
	delete temp.deliveredQty;
	delete temp.serialNumber;
	delete temp.remainingQty;
	delete temp.usedQty;
	delete temp.usedTotal;
	delete temp.remainingTotal;
	delete temp.remainingQty;
	// delete temp.deliveredTotal;
	delete temp.quantityInStock;
	delete temp.procedureUsedIn;
	return temp;
};
