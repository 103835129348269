import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "shards-react";
import suppliersApi from "../api/suppliers";
import PageTitle from "../components/common/PageTitle";
import SupplierForm from "../components/SupplierForm/AddSupplierForm";

const RegisterSuppliers = () => {
	const [data, setData] = useState({
		supplierName: "",
		address: { email: "", phoneNumber: "" },
		country: "",
	});

	const navigate = useNavigate();

	const setPhoneNumber = (value) => {
		let temp = { ...data };
		temp.address.phoneNumber = value;
		setData(temp);
	};
	const setEmail = (event) => {
		let temp = { ...data };
		temp.address.email = event.target.value;
		setData(temp);
	};

	const handleChange = (event) => {
		const { name, value } = event.target;

		let temp = { ...data };
		temp[name] = value;
		setData(temp);
	};
	const handleSubmit = () => {
		toast.promise(suppliersApi.add(data, onSuccess, onFailure), {
			pending: "Adding Supplier....",
		});
	};

	const onFailure = (error) => {
		console.log("This went Off ", error);
	};

	const onSuccess = () => {
		setTimeout(() => {
			navigate(-1);
		}, 2000);
	};

	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<ToastContainer />
			<Row noGutters className='page-header py-4'>
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to='/admin/suppliers'>Suppliers</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>
						<PageTitle
							title=''
							subtitle='Register Supplier'
							md='12'
							className='ml-sm-auto mr-sm-auto'
						/>
					</BreadcrumbItem>
				</Breadcrumb>
			</Row>

			<Col lg='12'>
				<SupplierForm
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					state={data}
					setPhoneNumber={setPhoneNumber}
					setEmail={setEmail}
				/>
			</Col>
		</Container>
	);
};

export default RegisterSuppliers;
