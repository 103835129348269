import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import userApi from "../api/user";
import Loading from "../components/loading/loading.page";

const Activate = () => {
	const navigate = useNavigate();
	const params = useParams();

	useEffect(() => {
		toast.promise(
			userApi.activate(
				{
					activation_token: params.id,
				},
				onSuccess,
				onFailure
			),
			{
				pending: "Activating .......",
			}
		);
		// eslint-disable-next-line
	}, []);

	const onFailure = (error) => {
		console.log("This went Off ", error);
	};

	const onSuccess = () => {
		setTimeout(() => {
			navigate("/");
		}, 1000);
	};

	return (
		<div>
			<ToastContainer />
			<Loading />
		</div>
	);
};

export default Activate;
