import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import * as React from "react";
import { useParams } from "react-router-dom";
import appointmentsApi from "../../api/Appointments";
import { formatAppointments } from "../../utils/formatResponse";
import Stepps from "./Stepps";

const steps = ["", "", "", ""];

export default function HorizontalLinearStepper() {
	const [activeStep, setActiveStep] = React.useState(0);
	const [patient, setPatient] = React.useState([]);
	const [skipped, setSkipped] = React.useState(new Set());

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const params = useParams();

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	React.useEffect(() => {
		appointmentsApi.getAll(onSuccess, onFailure);
		// eslint-disable-next-line
	}, []);

	const onSuccess = (resp) => {
		setPatient(
			formatAppointments(resp.data.appointments).find(
				(appt) => appt._id === params.id
			)
		);
	};
	const onFailure = (error) => {
		console.log("This went Off", error);
	};

	return (
		<Box sx={{ width: "100%" }}>
			{activeStep !== 3 && (
				<div className='flex' style={{ paddingBottom: "30px" }}>
					<p>Patient : {patient.patientName}</p>
					<p>Appointment Date : {patient.apptDate}</p>
					<p>Reason for visit : {patient.reason}</p>

					<p>Appointment Time : {patient.apptTime}</p>
				</div>
			)}
			<Stepper activeStep={activeStep}>
				{steps.map((label, index) => {
					const stepProps = {};
					const labelProps = {};

					if (isStepSkipped(index)) {
						stepProps.completed = false;
					}
					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>

			<React.Fragment>
				<div style={{ padding: "30px 0" }}>
					<Stepps
						activeStep={activeStep}
						onNext={handleNext}
						onPrev={handleBack}
						patient={patient}
					/>
				</div>
				<Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}></Box>
			</React.Fragment>
		</Box>
	);
}
