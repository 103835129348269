import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	Card,
	FormInput,
	FormSelect,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
} from "shards-react";
import login from "../assets/images/login.png";
import logo from "../assets/images/logo.png";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import userApi from "../api/user";

const LogIn = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [valid, setValid] = useState("");
	const [gender, setGender] = useState("male");

	useEffect(() => {
		if (password === confirmPassword && password.length > 1) {
			setValid(false);
		} else if (
			password !== confirmPassword &&
			password.length > 1 &&
			confirmPassword.length > 1
		) {
			setValid(true);
		}
	}, [password, confirmPassword]);

	const handleLogin = (e) => {
		e.preventDefault();
		toast.promise(
			userApi.register(
				{
					password: password,
					email: email,
					firstName: firstName,
					lastName: lastName,
					phoneNumber: phoneNumber,
					gender: gender,
				},
				onSuccess,
				onFailure
			),
			{
				pending: "Registering please wait....",
			}
		);
	};

	const onFailure = (error) => {
		console.log("This went Off ", error);
	};

	const onSuccess = (data) => {
		toast.success("Registerd  successfully !");

		setTimeout(() => {
			navigate("/");
		}, 2000);
	};

	return (
		<div>
			<ToastContainer />
			<div className='top-nav'>
				<img className='logo' src={logo} alt='logo' />
			</div>
			<div className='register'>
				<img src={login} className='login-vector' alt='home vector' />
				<form className='signup-form' onSubmit={handleLogin} method='post'>
					<Card className='form-card'>
						<Row>
							<h2 style={{ marginBottom: "30px" }}>Register</h2>
						</Row>

						<Row style={{ marginBottom: "20px" }} className='grid-two'>
							<InputGroup seamless className='mb-3 height-50'>
								<InputGroupAddon type='prepend'>
									<InputGroupText>
										<i className='material-icons'>person</i>
									</InputGroupText>
								</InputGroupAddon>
								<FormInput
									name='firstName'
									required
									onChange={(e) => {
										setFirstName(e.target.value);
									}}
									placeholder='firstName'
								/>
							</InputGroup>{" "}
							<InputGroup seamless className='mb-3 height-50'>
								<InputGroupAddon type='prepend'>
									<InputGroupText>
										<i className='material-icons'>person</i>
									</InputGroupText>
								</InputGroupAddon>
								<FormInput
									name='lastName'
									required
									onChange={(e) => {
										setLastName(e.target.value);
									}}
									type='text'
									placeholder='Last Name'
								/>
							</InputGroup>
						</Row>
						<Row style={{ marginBottom: "20px" }} className='grid-two'>
							<InputGroup seamless className='mb-3 height-50'>
								<InputGroupAddon type='prepend'>
									<InputGroupText>
										<i className='material-icons'>mail</i>
									</InputGroupText>
								</InputGroupAddon>
								<FormInput
									value={email}
									name='email'
									required
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									placeholder='Email'
								/>
							</InputGroup>
							<InputGroup seamless className='mb-3 height-50'>
								<InputGroupAddon type='prepend'>
									<InputGroupText>
										<i className='material-icons'>phone</i>
									</InputGroupText>
								</InputGroupAddon>
								<FormInput
									name='password'
									required
									onChange={(e) => {
										setPhoneNumber(e.target.value);
									}}
									placeholder='password'
								/>
							</InputGroup>
						</Row>

						<Row style={{ marginBottom: "20px" }} className='grid-two'>
							<FormSelect
								id='feCategory'
								required
								name='category'
								onChange={(e) => {
									setGender(e.target.value);
								}}
							>
								<option selected='true' value='' disabled='disabled'>
									Gender
								</option>
								<option value='male'>Male</option>
								<option value='frmale'>Female</option>
							</FormSelect>
						</Row>

						<Row style={{ marginBottom: "20px" }} className='grid-two'>
							<InputGroup seamless className='mb-3 height-50'>
								<InputGroupAddon type='prepend'>
									<InputGroupText>
										<i className='material-icons'>password</i>
									</InputGroupText>
								</InputGroupAddon>
								<FormInput
									type='password'
									name='password'
									required
									onChange={(e) => {
										setPassword(e.target.value);
									}}
									placeholder='Password'
									invalid={valid}
									valid={valid === false}
								/>
							</InputGroup>
							<InputGroup seamless className='mb-3 height-50'>
								<InputGroupAddon type='prepend'>
									<InputGroupText>
										<i className='material-icons'>password</i>
									</InputGroupText>
								</InputGroupAddon>
								<FormInput
									name='password'
									required
									onChange={(e) => {
										setConfirmPassword(e.target.value);
									}}
									type='password'
									placeholder='confirm Password'
									invalid={valid}
									valid={valid === false}
								/>
							</InputGroup>
						</Row>
						<Row
							style={{
								display: "flex!important",
								width: "100%",
								justifyContent: "space-between",
							}}
						>
							<span></span>
							<Link to='/' style={{ float: "right" }}>
								Alredy Have An Account ? Login
							</Link>
						</Row>
						<Row style={{ margin: "10px auto" }}>
							<button className='login-button' type='submit'>
								Register
							</button>
						</Row>
					</Card>
				</form>
			</div>
		</div>
	);
};

export default LogIn;
