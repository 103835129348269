import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import {
	Card,
	CardHeader,
	Col,
	Form,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";
import categoriesApi from "../../api/categories";
import proceduresApi from "../../api/procedures";
import suppliersApi from "../../api/suppliers";
import { categoriesAdded } from "../../store/categories-reducer";
import { proceduresAdded } from "../../store/procedure-reducer";
import { suppliersAdded } from "../../store/supplier-reducer";
import { cropDate } from "../../utils/crop-date";

const ProductDetailView = ({ title, handleChange, state }) => {
	const [categories, setCategories] = useState([]);
	const [suppliers, setSuppliers] = useState([]);
	const [procedures, setProcedures] = useState([]);

	const dispatch = useDispatch();
	useEffect(() => {
		categoriesApi.getAll(catOnSuccess, onFailure);
		suppliersApi.getAll(supplierOnSuccess, onFailure);
		proceduresApi.getAll(procedureOnSuccess, onFailure);
		// eslint-disable-next-line
	}, []);

	const procedureOnSuccess = (data) => {
		dispatch(proceduresAdded(data.data.procedures));
		setProcedures(data.data.procedures);
	};
	const onFailure = (error) => {
		console.log("This went Off ", error);
	};
	const supplierOnSuccess = (data) => {
		dispatch(suppliersAdded(data.data.suppliers));
		setSuppliers(data.data.suppliers);
	};

	const catOnSuccess = (data) => {
		dispatch(categoriesAdded(data.data.categories));
		setCategories(data.data.categories);
	};

	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>{title}</h6>
			</CardHeader>
			<ListGroup flush>
				<ListGroupItem className='p-3'>
					<Row>
						<Col>
							<Form>
								<Row form>
									{/* First Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Product Name</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{state.itemName}
										</span>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feTextArea'>Description</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{state.description}
										</span>
									</Col>
									{/* Last Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='fePurcahePrice'>Purchase Price</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{state.purchasePrice.amount}{" "}
											{state.purchasePrice.currency}
										</span>
									</Col>

									{/* Email */}
									<Col md='6' className='form-group'>
										<label htmlFor='feCategory'>Category</label>

										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{categories.find((cat) => cat._id === state.category) &&
												categories.find((cat) => cat._id === state.category)
													.categoryName}
										</span>
									</Col>

									<Col md='6' className='form-group'>
										<label htmlFor='feCategory'>Procedure Used In</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{state &&
											procedures.find(
												(procedure) =>
													procedure._id === state.proceduresUsedIn[0]
											)
												? procedures.find(
														(procedure) =>
															procedure._id === state.proceduresUsedIn[0]
												  ).procedureName
												: ""}
										</span>
									</Col>

									<Col md='6' className='form-group'>
										<label htmlFor='feSupplier'>Supplier</label>

										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{suppliers.find(
												(supplier) => supplier._id === state.supplier
											)
												? suppliers.find(
														(supplier) => supplier._id === state.supplier
												  ).supplierName
												: ""}
										</span>
									</Col>

									<Col md='6' className='form-group'>
										<label htmlFor='feDeliveryDate'>Delivery Date</label>

										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{cropDate(state.deliveryDate)}
										</span>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feDeliveryDate'>Expiry Date</label>

										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{cropDate(state.expiryDate)}
										</span>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feSerial'>Lot Number/ Serial Number</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{state.serialNumber}
										</span>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feDeliveryDate'>Quantity</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{state.deliveredQty}
										</span>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feDeliveryDate'>Size</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{state.size}
										</span>
									</Col>

									<Col md='6' className='form-group'>
										<label htmlFor='feSelling'>Selling Price</label>
										<span
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											className='form-control'
										>
											{state.sellingPrice.amount} {state.sellingPrice.currency}
										</span>
									</Col>

									{/* Password */}
								</Row>
							</Form>
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

ProductDetailView.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

ProductDetailView.defaultProps = {
	title: "Product Details",
};

export default ProductDetailView;
