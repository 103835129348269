import MaterialTable from "material-table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Row,
} from "shards-react";
import diagnosisApi from "../api/diagnosis";
import orderApi from "../api/order";
import proceduresApi from "../api/procedures";
import productApi from "../api/product";
import PageTitle from "../components/common/PageTitle";
import Featching from "../components/Featching/featching";
import { diagnosisAdded } from "../store/diagnosis-reducer";
import { ordersAdded } from "../store/orders-reducer";
import { proceduresAdded } from "../store/procedure-reducer";
import { productsAdded } from "../store/products-reducer";
import { formatOrders } from "../utils/formatResponse";

const Orders = () => {
	const navigate = useNavigate();
	const columns = [
		{ title: "Patient Name", field: "patientName" },
		{
			title: "Procedure",
			field: "procedureUsedIn",
		},

		{
			title: "Diagnosis",
			field: "diagnosis",
		},
		{
			title: "Items",
			field: "items",
			width: "80%",
		},
		{
			title: " Procedure Price",
			field: "procedurePrice",
		},
		{
			title: " T.Price",
			field: "price",
		},

		{
			title: "Date",
			field: "date",
		},
		{
			title: "Time",
			field: "time",
		},

		{
			title: "Status",
			field: "status",
			editable: false,
			render: (rowData) =>
				rowData.status === "approved" ? (
					<span theme='warning'>Approved</span>
				) : (
					<Button
						theme='success'
						onClick={() => {
							handleApprove(rowData);
							setLoading(true);
						}}
					>
						Approve
					</Button>
				),
		},
	];
	const [fetch, setFetsh] = useState(true);
	const [loading, setLoading] = useState(true);

	const [data, setData] = useState([]);
	const items = useSelector((state) => state.products);
	const procedures = useSelector((state) => state.procedures);
	const diagnosis = useSelector((state) => state.diagnosis);

	const dispatch = useDispatch();
	useEffect(() => {
		diagnosisApi.getAll(onDiagnosisSuccess, onFailure);
		productApi.getAll(onProductSuccess, onFailure);
		proceduresApi.getAll(onProcedureSuccess, onFailure);
		orderApi.getAll(onOrderSuccess, onFailure);
		// eslint-disable-next-line
	}, [fetch]);

	const onOrderSuccess = (resp) => {
		setData(
			formatOrders(resp.data.orders, items, procedures, diagnosis).reverse()
		);
		dispatch(ordersAdded(resp.data.orders));
		setLoading(false);
	};
	const onProcedureSuccess = (resp) => {
		// setProcedures(resp.data.procedures);
		dispatch(proceduresAdded(resp.data.procedures));
	};
	const onDiagnosisSuccess = (resp) => {
		dispatch(diagnosisAdded(resp.data.allDiagnosis));
	};
	const onProductSuccess = (resp) => {
		dispatch(productsAdded(resp.data.items));
	};
	const onApproveSuccess = (resp) => {
		setFetsh(!fetch);
	};

	const onFailure = (error) => {
		// dispatch(productsAdded([]));
		console.log("This went Off ", error);
	};

	const handleApprove = (rowdata) => {
		orderApi.approve(rowdata._id, onApproveSuccess, onFailure);
	};

	const handleDelete = (id) => {
		setFetsh(!fetch);
		orderApi.deleteOne(
			id,
			() => toast.success("Order deleted successfully!"),
			onFailure
		);
	};
	return (
		<Container fluid className='main-content-container px-4'>
			{/* Page Header */}
			<Row noGutters className='page-header py-4'>
				<PageTitle sm='4' title='Orders' className='text-sm-left' />
			</Row>
			<ToastContainer />

			<Row>
				<Col>
					<Card small className='mb-4 overflow-hidden'>
						<CardHeader
							className='bg-dark'
							style={{
								display: "flex",
								justifyContent: "space-between",
								zIndex: 99,
							}}
						>
							<h6 className='m-0 text-white'>All Orders</h6>
						</CardHeader>
						<CardBody className='bg-light p-0 '>
							<MaterialTable
								title={""}
								options={{
									exportButton: true,
									actionsColumnIndex: -1,
									search: true,
								}}
								columns={columns}
								data={data}
								editable={{
									onRowDelete: (oldData) =>
										new Promise((resolve, reject) => {
											setTimeout(() => {
												handleDelete(oldData._id);
												resolve();
											}, 1000);
										}),
								}}
								icons={{ Add: () => "Add Row" }}
								actions={[
									{
										icon: "edit",
										tooltip: "Edit",
										onClick: (event, rowData) => {
											navigate(
												`/admin/update-order/${rowData._id}/${rowData.appointmentId}`
											);
										},
									},
								]}
							/>
							{loading && <Featching />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Orders;
