import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	FormInput,
	FormSelect,
	FormTextarea,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";
import categoriesApi from "../../api/categories";
import proceduresApi from "../../api/procedures";
import suppliersApi from "../../api/suppliers";
import { categoriesAdded } from "../../store/categories-reducer";
import { proceduresAdded } from "../../store/procedure-reducer";
import { suppliersAdded } from "../../store/supplier-reducer";

const AddPrductForm = ({
	title,
	handleChange,
	handleSubmit,
	state,
	setCurrency,
	currency,
}) => {
	const [categories, setCategories] = useState([]);
	const [procedures, setProcedures] = useState([]);
	const [suppliers, setSuppliers] = useState([]);

	const dispatch = useDispatch();
	useEffect(() => {
		categoriesApi.getAll(catOnSuccess, onFailure);
		suppliersApi.getAll(supplierOnSuccess, onFailure);
		proceduresApi.getAll(procedureOnSuccess, onFailure);
		// eslint-disable-next-line
	}, []);

	const supplierOnSuccess = (data) => {
		dispatch(suppliersAdded(data.data.suppliers));
		setSuppliers(data.data.suppliers);
	};

	const procedureOnSuccess = (data) => {
		dispatch(proceduresAdded(data.data.procedures));
		let temp = [];
		data.data.procedures.map((e) =>
			temp.push({ value: e._id, label: e.procedureName })
		);
		setProcedures(temp);
	};

	const catOnSuccess = (data) => {
		dispatch(categoriesAdded(data.data.categories));
		setCategories(data.data.categories);
	};
	const onFailure = (error) => {
		console.log("This went Off ", error);
	};

	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>{title}</h6>
			</CardHeader>
			<ListGroup flush>
				<ListGroupItem className='p-3'>
					<Row>
						<Col>
							<Form
								method='post'
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
							>
								<Row form>
									{/* First Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Product Name</label>
										<FormInput
											id='feProductName'
											placeholder='Product Name'
											name='itemName'
											// value={state.name}
											required
											onChange={handleChange}
										/>
									</Col>

									{/* Last Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='fePurcahePrice'>Purchase Price</label>
										<FormInput
											id='fePurchasePrice'
											placeholder='Purcahes Price'
											name='purchasePrice'
											required
											type='number'
											onChange={handleChange}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feSelling'>Selling Price margin in %</label>
										<FormInput
											required
											type='number'
											id='feSelling'
											placeholder='Selling Price margin in %'
											name='sellingPrice'
											onChange={(e) =>
												handleChange({
													target: {
														name: "sellingPrice",
														value:
															parseInt(state.purchasePrice.amount) +
															Math.ceil(
																(state.purchasePrice.amount * e.target.value) /
																	100 /
																	10
															) *
																10,
													},
												})
											}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feSelling'>Currency</label>
										<FormSelect
											id='feCategory'
											required
											name='currency'
											// onChange={handleChange}
										>
											<option selected='true' value='' disabled='disabled'>
												Currency
											</option>

											<option value='ETB'>ETB</option>
										</FormSelect>
									</Col>

									{/* Email */}
									<Col md='6' className='form-group'>
										<label htmlFor='feCategory'>Category</label>
										<FormSelect
											id='feCategory'
											required
											name='category'
											onChange={handleChange}
										>
											<option selected='true' value='' disabled='disabled'>
												Categories
											</option>
											{categories.map((cat) => (
												<option value={cat._id}>{cat.categoryName}</option>
											))}
										</FormSelect>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feCategory'>Procedure Used In</label>

										<Select
											defaultValue={[]}
											isMulti
											onChange={(newValue, actionMeta) => {
												let temp = [];

												newValue.map((data) => {
													temp.push(data.value);
												});

												handleChange({
													target: { name: "proceduresUsedIn", value: temp },
												});
											}}
											name='colors'
											options={procedures}
											className='basic-multi-select'
											classNamePrefix='select'
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feSupplier'>Supplier</label>
										<FormSelect
											id='feSupplier'
											required
											name='supplier'
											onChange={handleChange}
										>
											<option selected='true' value='' disabled='disabled'>
												Suppliers
											</option>

											{suppliers.map((supp) => (
												<option value={supp._id}>{supp.supplierName}</option>
											))}
										</FormSelect>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feSerial'>Lot Number/ Serial Number</label>
										<FormInput
											type='text'
											required
											id='feSerial'
											placeholder='Lot Number/ Serial Number'
											name='serialNumber'
											onChange={handleChange}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feSerial'>Item Code</label>
										<FormInput
											type='text'
											required
											id='feSerial'
											placeholder='Item Code'
											name='itemCode'
											onChange={handleChange}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feDeliveryDate'>Expiry Date</label>
										<FormInput
											type='date'
											required
											id='feDelivery'
											// placeholder=''
											name='expiryDate'
											onChange={handleChange}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feDeliveryDate'>Delivery Date</label>
										<FormInput
											type='date'
											required
											id='feDelivery'
											// placeholder=''
											name='deliveryDate'
											onChange={handleChange}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feDeliveryDate'>Quantity</label>
										<FormInput
											required
											type='text'
											id='feDelivery'
											// placeholder=''
											name='deliveredQty'
											onChange={handleChange}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feDeliveryDate'>Size</label>
										<FormInput
											required
											type='text'
											id='feDelivery'
											// placeholder=''
											name='size'
											onChange={handleChange}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feTextArea'>Description</label>
										<FormTextarea
											type='textarea'
											id='feTextArea'
											placeholder='Description'
											name='description'
											// value={state.description}
											required
											onChange={handleChange}
										/>
									</Col>

									{/* Password */}
								</Row>

								<Button theme='accent' disabled={state.loading} type='submit'>
									Save Product
								</Button>
							</Form>
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

AddPrductForm.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

AddPrductForm.defaultProps = {
	title: "Product Details",
};

export default AddPrductForm;
