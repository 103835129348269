import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "shards-react";
import proceduresApi from "../api/procedures";
import PageTitle from "../components/common/PageTitle";
import EditProcedureForm from "../components/ProcedureForm/EditProcedureForm";
import { proceduresAdded } from "../store/procedure-reducer";
import { removeKey } from "../utils/deleteKeys";

const EditProcedures = () => {
	const [procedure, setProcedure] = useState([]);

	const dispatch = useDispatch();
	const params = useParams();
	useEffect(() => {
		proceduresApi.getAll(catOnSuccess, onFailure);
		// eslint-disable-next-line
	}, []);

	const catOnSuccess = (data) => {
		dispatch(proceduresAdded(data.data.procedures));
		setProcedure(data.data.procedures.find((cat) => cat._id === params.id));
	};
	const onFailure = (error) => {
		console.log("This went Off ", error);
	};
	const navigate = useNavigate();

	const handleChange = (event) => {
		const { name, value } = event.target;
		let temp = { ...procedure };
		temp[name] = value;
		setProcedure(temp);
	};
	const handleSubmit = () => {
		toast.promise(
			proceduresApi.updateOne(
				removeKey(procedure),
				procedure._id,
				onSuccess,
				onFailure
			),
			{
				pending: "Saving Procedure....",
			}
		);
	};

	const onSuccess = () => {
		setTimeout(() => {
			navigate(-1);
		}, 2000);
	};

	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<ToastContainer />
			<Row noGutters className='page-header py-4'>
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to='/admin/procedures'>Procedures</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>
						<PageTitle
							title=''
							subtitle='Edit Procedures'
							md='12'
							className='ml-sm-auto mr-sm-auto'
						/>
					</BreadcrumbItem>
				</Breadcrumb>
			</Row>

			<Col lg='12'>
				<EditProcedureForm
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					state={procedure}
				/>
			</Col>
		</Container>
	);
};

export default EditProcedures;
