import { combineReducers } from "@reduxjs/toolkit";
import categoriesReducer from "./categories-reducer";
import diagnosisReducer from "./diagnosis-reducer";
import ordersReducer from "./orders-reducer";
import procedureReducer from "./procedure-reducer";

import productsReducer from "./products-reducer";
import supplierReducer from "./supplier-reducer";
import userReducer from "./user-reducer";
import usersReducer from "./users-reducer";

export const allReducers = combineReducers({
	user: userReducer,
	users: usersReducer,
	products: productsReducer,
	categories: categoriesReducer,
	suppliers: supplierReducer,
	procedures: procedureReducer,
	orders: ordersReducer,
	diagnosis: diagnosisReducer,
});

export const entitiesReducer = (state, action) => {
	if (action.type === "RESET_APP") {
		state = undefined;
	}

	return allReducers(state, action);
};
