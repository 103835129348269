import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "categories",
	initialState: [],
	reducers: {
		categoriesAdded: (categories, action) => {
			return [...action.payload];
		},
	},
});

export const { categoriesAdded } = slice.actions;

export default slice.reducer;
