import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "shards-react";
import statsticsApi from "../api/statstics";
import AdminStats from "../components/common/AdminStats";
import PageTitle from "../components/common/PageTitle";

const AdminOverview = () => {
	const [smallStats, setSmallStats] = useState([
		{
			label: "Total Items",
			value: "0",
			percentage: "4.7%",
			increase: true,
			chartLabels: [null, null, null, null, null, null, null],
			attrs: { md: "6", sm: "6" },
			datasets: [
				{
					label: "Today",
					fill: "start",
					borderWidth: 1.5,
					backgroundColor: "rgba(0, 184, 216, 0.1)",
					borderColor: "rgb(0, 184, 216)",
					data: [0, 1, 2, 3, 4, 3, 6],
				},
			],
		},
		{
			label: "Total Categories",
			value: "0",
			percentage: "4.7%",
			increase: true,
			chartLabels: [null, null, null, null, null, null, null],
			attrs: { md: "6", sm: "6" },
			datasets: [
				{
					label: "Today",
					fill: "start",
					borderWidth: 1.5,
					backgroundColor: "rgba(0, 184, 216, 0.1)",
					borderColor: "rgb(0, 0, 216)",
					data: [1, 2, 1, 3, 5, 4, 7],
				},
			],
		},
		{
			label: "Total Procedures",
			value: "0",
			percentage: "12.4",
			increase: true,
			chartLabels: [null, null, null, null, null, null, null],
			attrs: { md: "6", sm: "6" },
			datasets: [
				{
					label: "Today",
					fill: "start",
					borderWidth: 1.5,
					backgroundColor: "rgba(23,198,113,0.1)",
					borderColor: "rgb(23,198,113)",
					data: [1, 2, 3, 3, 3, 4, 4],
				},
			],
		},
	]);

	useEffect(() => {
		statsticsApi.getAll(onDataSuccess, onFailure);
		// eslint-disable-next-line
	}, []);

	const onDataSuccess = (data) => {
		let prevState = [...smallStats];
		prevState[0].value = data.data.totalItems;
		prevState[1].value = data.data.totalCategories;
		prevState[2].value = data.data.totalProcedures;
		setSmallStats(prevState);
	};
	const onFailure = (error) => {
		console.log("This went Off ", error);
	};

	return (
		<Container fluid className='main-content-container px-4'>
			{/* Page Header */}
			<Row noGutters className='page-header py-4'>
				<PageTitle
					title="Adminstrator's Overview"
					subtitle='Dashboard'
					className='text-sm-left mb-3'
				/>
			</Row>

			{/* Small Stats Blocks */}
			<Row>
				{smallStats.map((stats, idx) => (
					<Col className='col-lg mb-4' key={idx} {...stats.attrs}>
						<AdminStats
							id={`small-stats-${idx}`}
							variation='1'
							chartData={stats.datasets}
							chartLabels={stats.chartLabels}
							label={stats.label}
							value={stats.value}
							percentage={stats.percentage}
							increase={stats.increase}
							decrease={stats.decrease}
						/>
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default AdminOverview;
