import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "shards-react";
import categoriesApi from "../api/categories";
import CategoryForm from "../components/CategoryForm/AddCategoryForm";
import PageTitle from "../components/common/PageTitle";

const RegisterCategory = () => {
	const [data, setData] = useState({
		categoryName: "",
		description: "",
	});

	const navigate = useNavigate();

	const handleChange = (event) => {
		const { name, value } = event.target;

		let temp = { ...data };
		temp[name] = value;
		setData(temp);
	};
	const handleSubmit = () => {
		toast.promise(categoriesApi.add(data, onSuccess, onFailure), {
			pending: "Adding Category....",
		});
	};

	const onFailure = (error) => {
		console.log("This went Off ", error);
	};
	const onSuccess = () => {
		setTimeout(() => {
			navigate(-1);
		}, 2000);
	};

	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<ToastContainer />
			<Row noGutters className='page-header py-4'>
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to='/admin/categories'>Categories</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>
						<PageTitle
							title=''
							subtitle='Register Category'
							md='12'
							className='ml-sm-auto mr-sm-auto'
						/>
					</BreadcrumbItem>
				</Breadcrumb>
			</Row>
			<Col lg='12'>
				<CategoryForm
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					state={data}
				/>
			</Col>
		</Container>
	);
};

export default RegisterCategory;
