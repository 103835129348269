import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	FormInput,
	FormSelect,
	FormTextarea,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";
import categoriesApi from "../../api/categories";
import proceduresApi from "../../api/procedures";
import suppliersApi from "../../api/suppliers";
import { categoriesAdded } from "../../store/categories-reducer";
import { proceduresAdded } from "../../store/procedure-reducer";
import { suppliersAdded } from "../../store/supplier-reducer";
import Loading from "../loading/loading.page";

const EditProductForm = ({ title, handleChange, handleSubmit, state }) => {
	const [categories, setCategories] = useState([]);
	const [suppliers, setSuppliers] = useState([]);
	const [procedures, setProcedures] = useState([]);
	const [defaultValues, setDefaultValues] = useState([]);
	const [loadingDefaultValues, setLoadingDefaultValues] = useState(true);

	const dispatch = useDispatch();
	useEffect(() => {
		setSellingPrice(
			Math.ceil(
				((state.sellingPrice.amount - state.purchasePrice.amount) *
					state.purchasePrice.amount) /
					100
			)
		);
	}, []);
	useEffect(() => {
		categoriesApi.getAll(catOnSuccess, onFailure);
		suppliersApi.getAll(supplierOnSuccess, onFailure);
		proceduresApi.getAll(procedureOnSuccess, onFailure);

		// eslint-disable-next-line
	}, []);

	const procedureOnSuccess = (data) => {
		dispatch(proceduresAdded(data.data.procedures));
		let temp = [];
		data.data.procedures.map((e) =>
			temp.push({ value: e._id, label: e.procedureName })
		);
		setProcedures(temp);

		let defaults = [];

		data.data.procedures
			.filter((procedure) =>
				state.proceduresUsedIn.join().includes(procedure._id)
			)
			.map((e) => defaults.push({ value: e._id, label: e.procedureName }));
		setProcedures(temp);

		setDefaultValues(defaults);
		setLoadingDefaultValues(false);
	};
	const onFailure = (error) => {
		console.log("This went Off ", error);
	};
	const supplierOnSuccess = (data) => {
		dispatch(suppliersAdded(data.data.suppliers));
		setSuppliers(data.data.suppliers);
	};

	const catOnSuccess = (data) => {
		dispatch(categoriesAdded(data.data.categories));
		setCategories(data.data.categories);
	};

	const [sellingPrice, setSellingPrice] = useState(0);

	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>{title}</h6>
			</CardHeader>
			{sellingPrice ? (
				<ListGroup flush>
					<ListGroupItem className='p-3'>
						<Row>
							<Col>
								<Form>
									<Row form>
										{/* First Name */}
										<Col md='6' className='form-group'>
											<label htmlFor='feProductName'>Product Name</label>
											<FormInput
												id='feProductName'
												placeholder='Product Name'
												name='itemName'
												value={state.itemName}
												onChange={handleChange}
											/>
										</Col>
										<Col md='6' className='form-group'>
											<label htmlFor='feProductName'>Item Code</label>
											<FormInput
												id='feProductName'
												placeholder='Item Code'
												required
												name='itemCode'
												value={state.itemCode}
												onChange={handleChange}
											/>
										</Col>
										<Col md='6' className='form-group'>
											<label htmlFor='feDeliveryDate'>Size</label>
											<FormInput
												required
												type='text'
												id='feDelivery'
												value={state.size}
												// placeholder=''
												name='size'
												onChange={handleChange}
											/>
										</Col>
										{/* Last Name */}
										<Col md='6' className='form-group'>
											<label htmlFor='fePurcahePrice'>Purchase Price</label>
											<FormInput
												id='fePurchasePrice'
												placeholder='Purcahes Price'
												name='purchasePrice'
												value={
													state.purchasePrice ? state.purchasePrice.amount : "-"
												}
												onChange={handleChange}
											/>
										</Col>

										<Col md='6' className='form-group'>
											<label htmlFor='feSelling'>
												Selling Price margin in %
											</label>

											<FormInput
												required
												type='number'
												value={sellingPrice}
												id='feSelling'
												placeholder='Selling Price margin in %'
												name='sellingPrice'
												onChange={(e) => {
													setSellingPrice(e.target.value);
													handleChange({
														target: {
															name: "sellingPrice",
															value:
																parseInt(state.purchasePrice.amount) +
																Math.ceil(
																	(state.purchasePrice.amount *
																		e.target.value) /
																		100 /
																		10
																) *
																	10,
														},
													});
												}}
											/>
										</Col>
										<Col md='6' className='form-group'>
											<label htmlFor='feSelling'>Currency</label>
											<FormSelect
												id='feCategory'
												required
												name='currency'
												// onChange={handleChange}
											>
												<option selected='true' value='' disabled='disabled'>
													Currency
												</option>

												<option value='ETB'>ETB</option>
											</FormSelect>
										</Col>

										{/* Email */}
										<Col md='6' className='form-group'>
											<label htmlFor='feCategory'>Category</label>
											<FormSelect
												id='feCategory'
												name='proceduresUsedIn'
												value={state.proceduresUsedIn}
												onChange={handleChange}
											>
												<option>Categories</option>
												{categories.map((procedure) => (
													<option value={procedure._id}>
														{procedure.categoryName}
													</option>
												))}
											</FormSelect>
										</Col>

										<Col md='6' className='form-group'>
											<label htmlFor='feSupplier'>Supplier</label>
											<FormSelect
												id='feSupplier'
												name='supplier'
												value={state.supplier}
												onChange={handleChange}
											>
												<option>Suppliers</option>

												{suppliers.map((supp) => (
													<option value={supp._id}>{supp.supplierName}</option>
												))}
											</FormSelect>
										</Col>

										{!loadingDefaultValues ? (
											<Col md='6' className='form-group'>
												<label htmlFor='feCategory'>Procedure Used In </label>

												{!loadingDefaultValues ? (
													<Select
														defaultValue={[...defaultValues]}
														isMulti
														onChange={(newValue, actionMeta) => {
															let temp = [];

															newValue.map((data) => {
																temp.push(data.value);
															});

															handleChange({
																target: {
																	name: "proceduresUsedIn",
																	value: temp,
																},
															});
														}}
														name='colors'
														options={procedures}
														className='basic-multi-select'
														classNamePrefix='select'
													/>
												) : (
													""
												)}
												{/* <FormSelect
											id='feCategory'
											name='proceduresUsedIn'
											value={state.category}
											onChange={handleChange}
										>
											<option>Procedure</option>
											{procedures.map((cat) => (
												<option value={cat._id}>{cat.procedureName}</option>
											))}
										</FormSelect> */}
											</Col>
										) : (
											""
										)}

										<Col md='6' className='form-group'>
											<label htmlFor='feDeliveryDate'>Delivery Date</label>
											<FormInput
												type='date'
												id='feDelivery'
												value={state.deliveryDate}
												name='deliveryDate'
												onChange={handleChange}
											/>
										</Col>
										<Col md='6' className='form-group'>
											<label htmlFor='feDeliveryDate'>Expiry Date</label>

											<FormInput
												type='date'
												id='feDelivery'
												value={state.expiryDate}
												name='expiryDate'
												onChange={handleChange}
											/>
										</Col>
										<Col md='6' className='form-group'>
											<label htmlFor='feDeliveryDate'>Quantity</label>

											<FormInput
												required
												type='text'
												id='feDelivery'
												value={state.deliveredQty}
												// placeholder=''
												name='deliveredQty'
												onChange={handleChange}
											/>
										</Col>

										{/* <Col md='6' className='form-group'>
										<label htmlFor='feSerial'>Lot Number/ Serial Number</label>
										<FormInput
											type='text'
											id='feSerial'
											placeholder='Lot Number/ Serial Number'
											name='serialNumber'
											value={state.serialNumber}
											onChange={handleChange}
										/>
									</Col> */}
										{/* <Col md='6' className='form-group'>
										<label htmlFor='feDeliveryDate'>Quantity</label>
										<FormInput
											type='text'
											id='feDelivery'
											value={state.deliveredQty}
											placeholder='Quantity'
											name='deliveredQty'
											onChange={handleChange}
										/>
									</Col> */}

										<Col md='6' className='form-group'>
											<label htmlFor='feTextArea'>Description</label>
											<FormTextarea
												type='textarea'
												id='feTextArea'
												placeholder='Description'
												name='description'
												value={state.description}
												onChange={handleChange}
											/>
										</Col>
										{/* Password */}
									</Row>

									<Button
										theme='accent'
										disabled={state.loading}
										onClick={handleSubmit}
									>
										Save Product
									</Button>
								</Form>
							</Col>
						</Row>
					</ListGroupItem>
				</ListGroup>
			) : (
				<Loading />
			)}
		</Card>
	);
};

EditProductForm.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

EditProductForm.defaultProps = {
	title: "Product Details",
};

export default EditProductForm;
