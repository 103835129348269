const func = () => {
	return [
		{
			title: "Dashboard",
			to: "/admin/overview",
			htmlBefore: '<i class="material-icons">bar_chart</i>',
			htmlAfter: "",
		},

		{
			title: "Appointments",
			htmlBefore: '<i class="material-icons">book</i>',
			to: "/admin/appointments",
		},
		// {
		// 	title: "Request Items",
		// 	htmlBefore: '<i class="material-icons">personal_injury</i>',
		// 	to: "/admin/request-item",
		// },]
		{
			title: "Manage Diagnosis",
			htmlBefore: '<i class="material-icons">add_card</i>',
			to: "/admin/diagnosis",
		},
		{
			title: "Products",
			htmlBefore: '<i class="material-icons">inventory</i>',
			to: "/admin/products",
		},

		{
			title: "Inventory",
			htmlBefore: '<i class="material-icons">store</i>',
			to: "/admin/store",
		},
		// {
		// 	title: "Register Suppliers",
		// 	htmlBefore: '<i class="material-icons">add</i>',
		// 	to: "/admin/register-suppliers",
		// },
		{
			title: "Suppliers",
			// htmlBefore: '<i class="material-icons">vertical_split</i>',
			htmlBefore: '<i class="material-icons">factory</i>',
			to: "/admin/suppliers",
		},
		// {
		// 	title: "Register Category ",
		// 	htmlBefore: '<i class="material-icons">class</i>',
		// 	to: "/admin/register-category",
		// },
		{
			title: "Categories ",
			htmlBefore: '<i class="material-icons">star</i>',
			to: "/admin/categories",
		},
		// {
		// 	title: "Register Procedures",
		// 	htmlBefore: '<i class="material-icons">queue_play_next</i>',
		// 	to: "/admin/register-procedures",
		// },
		{
			title: " Procedures",
			htmlBefore: '<i class="material-icons">remove_from_queue</i>',
			to: "/admin/procedures",
		},
		{
			title: " Orders",
			htmlBefore: '<i class="material-icons">list</i>',
			to: "/admin/orders",
		},
		{
			title: "Users",
			htmlBefore: '<i class="material-icons">person</i>',
			to: "/admin/users",
		},
	];
};

export default func;
