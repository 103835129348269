import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "shards-react";
import productApi from "../api/product";
import PageTitle from "../components/common/PageTitle";
import Loading from "../components/loading/loading.page";

import EditProductForm from "../components/ProductForm/EditProductForm";
import { removeKey } from "../utils/deleteKeys";

const EditProduct = () => {
	const params = useParams();
	const [product, setProduct] = useState({});
	const navigate = useNavigate();

	const handleChange = (event) => {
		const { name, value } = event.target;
		let temp = { ...product };
		if (name === "purchasePrice") {
			temp.purchasePrice = {
				currency: "ETB",
				amount: parseInt(value),
			};
		} else if (name === "sellingPrice") {
			temp.sellingPrice = {
				currency: "ETB",
				amount: parseInt(value),
			};
		} else {
			temp[name] = value;
		}
		setProduct(temp);
	};
	useEffect(() => {
		productApi.getOne(params.id, onFetchSuccess, onFailure);
	}, []);
	const handleSubmit = () => {
		toast.promise(
			productApi.updateProduct(
				removeKey(product),
				params.id,
				onSuccess,
				onFailure
			),
			{
				pending: "Saving Product....",
			}
		);
	};

	const onFailure = (error) => {
		console.log("This went Off ", error);
	};

	const onSuccess = () => {
		setTimeout(() => {
			navigate(-1);
		}, 2000);
	};
	const onFetchSuccess = (resp) => {
		setProduct(resp.data.item);
	};

	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<ToastContainer />
			<Row noGutters className='page-header py-4'>
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to='/admin/products'>Products</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>
						<PageTitle
							title=''
							subtitle='Edit Products'
							md='12'
							className='ml-sm-auto mr-sm-auto'
						/>
					</BreadcrumbItem>
				</Breadcrumb>
			</Row>

			<Col lg='12'>
				{!product.purchasePrice && <Loading />}
				{product.purchasePrice && (
					<EditProductForm
						handleChange={handleChange}
						handleSubmit={handleSubmit}
						state={product}
					/>
				)}
			</Col>
		</Container>
	);
};

export default EditProduct;
