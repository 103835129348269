import { apiCall, apiFetch } from "./ApiCall";
const productApi = {
	add: (data, onSuccess, onFailure) =>
		apiCall("post", data, "/item/", onSuccess, onFailure),
	upladcvs: (file, onSuccess, onFailure) =>
		apiCall("post", file, "/item/uploaditemsFromExcel", onSuccess, onFailure),
	getAll: (onSuccess, onFailure) => {
		apiFetch("get", "/item/", onSuccess, onFailure);
	},
	getInventory: (onSuccess, onFailure) => {
		apiFetch("get", "/item/status/", onSuccess, onFailure);
	},
	getOne: (id, onSuccess, onFailure) => {
		apiFetch("get", `/item/${id}`, onSuccess, onFailure);
	},
	filter: (query, onSuccess, onFailure) => {
		apiFetch("get", `/item/filter?${query}`, onSuccess, onFailure);
	},
	deleteOne: (id, onSuccess, onFailure) => {
		apiFetch("delete", `/item/${id}`, onSuccess, onFailure);
	},
	updateOne: (data, id, onSuccess, onFailure) => {
		apiCall(
			"put",
			data,
			`/patient/order-tools-to-patient/${id}`,
			onSuccess,
			onFailure
		);
	},
	updateProduct: (data, id, onSuccess, onFailure) => {
		apiCall("put", data, `/item/${id}`, onSuccess, onFailure);
	},
	updateProductQuantity: (data, id, onSuccess, onFailure) => {
		apiCall("put", data, `/item/${id}/update-quantity`, onSuccess, onFailure);
	},
	requestProduct: (data, onSuccess, onFailure) => {
		apiCall(
			"post",
			data,
			`/patient/order-tools-to-patient`,
			onSuccess,
			onFailure
		);
	},
};

export default productApi;
