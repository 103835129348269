import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "shards-react";
import categoriesApi from "../api/categories";
import suppliersApi from "../api/suppliers";
import EditCategoryForm from "../components/CategoryForm/EditCategoryForm";
import PageTitle from "../components/common/PageTitle";
import { categoriesAdded } from "../store/categories-reducer";
import { suppliersAdded } from "../store/supplier-reducer";
import { removeKey } from "../utils/deleteKeys";

const EditCategory = () => {
	const [category, setCategory] = useState([]);
	const [suppliers, setSuppliers] = useState([]);

	const dispatch = useDispatch();
	const params = useParams();
	useEffect(() => {
		categoriesApi.getAll(catOnSuccess, onFailure);
		suppliersApi.getAll(supplierOnSuccess, onFailure);
		// eslint-disable-next-line
	}, []);

	const supplierOnSuccess = (data) => {
		dispatch(suppliersAdded(data.data.suppliers));
		setSuppliers(data.data.suppliers);
	};
	const onFailure = (error) => {
		console.log("This went Off ", error);
	};
	const catOnSuccess = (data) => {
		dispatch(categoriesAdded(data.data.categories));
		setCategory(data.data.categories.find((cat) => cat._id === params.id));
	};

	const navigate = useNavigate();

	const handleChange = (event) => {
		const { name, value } = event.target;
		let temp = { ...category };
		temp[name] = value;
		setCategory(temp);
	};
	const handleSubmit = () => {
		toast.promise(
			categoriesApi.updateOne(
				removeKey(category),
				category._id,
				onSuccess,
				onFailure
			),
			{
				pending: "Saving Product....",
			}
		);
	};

	const onSuccess = () => {
		setTimeout(() => {
			navigate(-1);
		}, 2000);
	};

	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<ToastContainer />
			<Row noGutters className='page-header py-4'>
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to='/admin/categories'>Categories</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>
						<PageTitle
							title=''
							subtitle='Edit Category'
							md='12'
							className='ml-sm-auto mr-sm-auto'
						/>
					</BreadcrumbItem>
				</Breadcrumb>
			</Row>

			<Col lg='12'>
				<EditCategoryForm
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					state={category}
					supplier={suppliers}
				/>
			</Col>
		</Container>
	);
};

export default EditCategory;
