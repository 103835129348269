import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "suppliers",
	initialState: [],
	reducers: {
		suppliersAdded: (suppliers, action) => {
			return [...action.payload];
		},
	},
});

export const { suppliersAdded } = slice.actions;

export default slice.reducer;
