import MaterialTable from "material-table";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Row,
} from "shards-react";
import suppliersApi from "../api/suppliers";
import PageTitle from "../components/common/PageTitle";
import Featching from "../components/Featching/featching";
import { suppliersAdded } from "../store/supplier-reducer";
import { formatSupplier } from "../utils/formatResponse";

const Suppliers = () => {
	const [data, setData] = useState();
	const columns = [
		{ title: "Name", field: "name" },
		{
			title: "Email",
			field: "email",
		},
		{ title: "Phone Number", field: "phoneNumber" },
		{ title: "Country", field: "country" },
	];
	const dispatch = useDispatch();
	const [fetch, setFetch] = useState(false);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	useEffect(() => {
		suppliersApi.getAll(onSuccess, onFailure);
		// eslint-disable-next-line
	}, [fetch]);

	const handleDelete = (id) => {
		suppliersApi.deleteOne(
			id,
			() => {
				toast.success("Supplier Deleted!");
				setFetch(!fetch);
			},
			onFailure
		);
	};

	const onSuccess = (data) => {
		dispatch(suppliersAdded(data.data.suppliers));
		setData(formatSupplier(data.data.suppliers));
		setLoading(false);
	};
	const onFailure = (error) => {
		dispatch(suppliersAdded([]));

		console.log("This went Off ", error);
	};

	return (
		<Container fluid className='main-content-container px-4'>
			{/* Page Header */}
			<Row noGutters className='page-header py-4'>
				<PageTitle sm='4' title='Suppliers' className='text-sm-left' />
			</Row>
			<ToastContainer />

			<Row>
				<Col>
					<Card small className='mb-4 overflow-hidden'>
						<CardHeader
							className='bg-dark'
							style={{
								display: "flex",
								justifyContent: "space-between",
								zIndex: 99,
							}}
						>
							<h6 className='m-0 text-white'>All Suppliers</h6>
							<Button
								theme='success'
								onClick={() => {
									navigate("/admin/register-supplier");
								}}
							>
								<span style={{ marginRight: "10px" }}>
									<i className='material-icons'>add</i>
								</span>
								Add New Supplier
							</Button>
						</CardHeader>
						<CardBody className='bg-light p-0 '>
							<MaterialTable
								title={""}
								options={{
									exportButton: true,
									actionsColumnIndex: -1,
									search: true,
								}}
								columns={columns}
								data={data}
								editable={{
									onRowDelete: (oldData) =>
										new Promise((resolve, reject) => {
											setTimeout(() => {
												handleDelete(oldData._id);
												resolve();
											}, 1000);
										}),
								}}
								actions={[
									{
										icon: "edit",
										tooltip: "Edit",
										onClick: (event, rowData) => {
											navigate(`/admin/edit-supplier/${rowData._id}`);
										},
									},
								]}
							/>
							{loading && <Featching />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Suppliers;
