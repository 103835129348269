import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "orders",
	initialState: [],
	reducers: {
		ordersAdded: (orders, action) => {
			return action.payload;
		},
	},
});

export const { ordersAdded } = slice.actions;

export default slice.reducer;
