import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import * as React from "react";
import { useParams } from "react-router-dom";
import orderApi from "../../api/order";
import { cropDate } from "../../utils/crop-date";
import Stepps from "./Stepps";

const steps = ["", "", "", ""];

export default function UpdateOrderSteper() {
	const [activeStep, setActiveStep] = React.useState(0);
	const [patient, setPatient] = React.useState([]);
	const [skipped, setSkipped] = React.useState(new Set());

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const params = useParams();

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	React.useEffect(() => {
		orderApi.getOne(params.id, onSuccess, onFailure);
		// eslint-disable-next-line
	}, []);

	const onSuccess = (resp) => {
		setPatient(resp.data.order);
	};
	const onFailure = (error) => {
		console.log("This went Off", error);
	};

	return (
		<Box sx={{ width: "100%" }}>
			{activeStep !== 3 && (
				<div className='flex' style={{ paddingBottom: "30px" }}>
					<p>Patient : {patient && patient.patientName}</p>
					<p>Order Date : {patient.orderDate && cropDate(patient.orderDate)}</p>
					<p>Age : {patient && patient.age}</p>

					<p>Appointment Time : {patient && patient.orderTime}</p>
				</div>
			)}
			<Stepper activeStep={activeStep}>
				{steps.map((label, index) => {
					const stepProps = {};
					const labelProps = {};

					if (isStepSkipped(index)) {
						stepProps.completed = false;
					}
					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>

			<React.Fragment>
				<div style={{ padding: "30px 0" }}>
					<Stepps
						activeStep={activeStep}
						onNext={handleNext}
						onPrev={handleBack}
						patient={patient}
					/>
				</div>
				<Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
					{/* <Button
						color='inherit'
						disabled={activeStep === 0}
						onClick={handleBack}
						sx={{ mr: 1 }}
					>
						Back
					</Button> */}
					{/* <Box sx={{ flex: "1 1 auto" }} /> */}

					{/* <Button onClick={handleNext}>
						{activeStep === steps.length - 1 ? "Finish" : "Next"}
					</Button> */}
				</Box>
			</React.Fragment>
		</Box>
	);
}
