import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
	Button,
	Col,
	Form,
	FormInput,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from "shards-react";
import diagnosisApi from "../../api/diagnosis";
import "./EditDiagnosis.styles.css";
const EditDiagnosis = ({ toggle, open, data, name, fetch, setFetch }) => {
	const [diagnosisName, setDiagnosis] = useState("");
	const [serialNumber, setsSerialNumber] = useState("");
	const [loading, setLoading] = useState(false);
	const handleSubmit = () => {
		setLoading(false);
		toast.promise(
			diagnosisApi.updateOne(
				{
					diagnosisName: diagnosisName,
					serialNumber: serialNumber,
				},
				data._id,
				onSuccess,
				onFaileure
			),
			{
				pending: "proccessing ...",
			}
		);
	};
	useEffect(() => {
		setDiagnosis(data.diagnosisName);
		setsSerialNumber(data.serialNumber);
	}, [data]);

	const onSuccess = () => {
		setLoading(false);
		setFetch(!fetch);
		toggle();
		setDiagnosis("");
		setsSerialNumber("");
	};
	const onFaileure = (error) => {
		console.log("This went Off", error);
		setLoading(false);
	};
	return (
		<Modal open={open} toggle={toggle} centered={true} size='md'>
			<ModalHeader>Edit {name} Diagnosis</ModalHeader>
			<Form
				method='post'
				onSubmit={(e) => {
					e.preventDefault();
					handleSubmit();
				}}
			>
				<ModalBody>
					<Col md='12' className='form-group'>
						<FormInput
							md='12'
							requ
							id='feDiagnosis'
							placeholder='Diagnosis Name'
							name='deptname'
							value={diagnosisName}
							onChange={(e) => setDiagnosis(e.target.value)}
							required
						/>
					</Col>
					<Col md='12' className='form-group'>
						<FormInput
							md='12'
							requ
							id='feDiagnosis'
							placeholder='serialNumber'
							name='serialNumber'
							value={serialNumber}
							onChange={(e) => setsSerialNumber(e.target.value)}
							required
						/>
					</Col>
				</ModalBody>
				<ModalFooter>
					<Button
						theme='success'
						style={{ zIndex: "99999999999" }}
						type='submit'
					>
						Update
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	);
};

export default EditDiagnosis;
