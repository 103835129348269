import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "users",
	initialState: [],
	reducers: {
		usersAdded: (users, action) => {
			return [action.payload];
		},
	},
});

export const { usersAdded } = slice.actions;

export default slice.reducer;
