import { apiCall, apiFetch } from "./ApiCall";
const orderApi = {
	add: (data, onSuccess, onFailure) => {
		apiCall(
			"post",
			data,
			`/patient/order-tools-to-patient`,
			onSuccess,
			onFailure
		);
	},
	update: (data, id, onSuccess, onFailure) => {
		apiCall(
			"put",
			data,
			`/patient/order-tools-to-patient/${id}`,
			onSuccess,
			onFailure
		);
	},
	getAll: (onSuccess, onFailure) => {
		apiFetch("get", "/patient/order-tools-to-patient", onSuccess, onFailure);
	},
	getOne: (id, onSuccess, onFailure) => {
		apiFetch(
			"get",
			`/patient/order-tools-to-patient/${id}`,
			onSuccess,
			onFailure
		);
	},
	deleteOne: (id, onSuccess, onFailure) => {
		apiFetch(
			"delete",
			`/patient/order-tools-to-patient/${id}`,
			onSuccess,
			onFailure
		);
	},
	approve: (id, onSuccess, onFailure) => {
		apiCall(
			"put",
			{},
			`/patient/order-tools-to-patient/${id}/approve`,
			onSuccess,
			onFailure
		);
	},
};

export default orderApi;
