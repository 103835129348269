import PropTypes from "prop-types";
import React from "react";
import PhoneInput from "react-phone-number-input";

import "react-phone-number-input/style.css";
import {
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	FormInput,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";

const SupplierForm = ({
	title,
	handleChange,
	handleSubmit,
	setPhoneNumber,
	setEmail,
}) => {
	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>{title}</h6>
			</CardHeader>
			<ListGroup flush>
				<ListGroupItem className='p-3'>
					<Row>
						<Col>
							<Form>
								<Row form>
									{/* First Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Supplier Name</label>
										<FormInput
											id='feProductName'
											placeholder='Supplier Name'
											name='supplierName'
											onChange={handleChange}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='feAddress'>Email</label>
										<FormInput
											id='feEmail'
											placeholder='Email'
											name='address.email'
											onChange={setEmail}
										/>
									</Col>
									{/* Last Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='fePurcahePrice'>Phone Number</label>
										<PhoneInput
											className='form-control'
											id='fePurchasePrice'
											placeholder='Country'
											name='address.phoneNumber'
											onChange={setPhoneNumber}
										/>
									</Col>
									<Col md='6' className='form-group'>
										<label htmlFor='fePurcahePrice'>Country</label>
										<FormInput
											id='fePurchasePrice'
											placeholder='Country'
											name='country'
											onChange={handleChange}
										/>
									</Col>

									{/* Password */}
								</Row>

								<Button theme='accent' onClick={handleSubmit}>
									Save Supplier
								</Button>
							</Form>
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

SupplierForm.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

SupplierForm.defaultProps = {
	title: "Supplier Details",
};

export default SupplierForm;
