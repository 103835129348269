import PropTypes from "prop-types";
import React from "react";

import "react-phone-number-input/style.css";
import {
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	InputGroup,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";

const SelectQuantity = ({
	title,
	onNext,
	onPrev,
	itemsToRequest,
	handleChangeInItemQuantity,
}) => {
	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>{title}</h6>
			</CardHeader>
			<ListGroup flush>
				<ListGroupItem className='p-3'>
					<Row>
						<Col>
							<Form
								method='post'
								onSubmit={(e) => {
									e.preventDefault();
									onNext();
								}}
							>
								<Row form>
									{itemsToRequest &&
										itemsToRequest
											.filter((item) => item.selected)
											.map((item) => (
												<Col md='5.7'>
													<InputGroup className='time-modal'>
														<label
															htmlFor='start'
															style={{
																padding: "0px 20px",
																fontSize: "15px",
																fontWeight: "200",
																lineHeight: "25px",
															}}
														>
															Name: {item.itemName} <br /> Size : {item.size}
															<br />
															Quantity In Stock : {item.remainingQty}
														</label>
														<input
															placeholder='Quantity'
															type='number'
															min={1}
															name={item.item}
															className='time-selector form-control custom-select'
															required
															onChange={handleChangeInItemQuantity}
															style={{ marginTop: "35px" }}
														/>
													</InputGroup>
												</Col>
											))}
									<Col md='10' className='form-group pt-3'>
										<Button theme='info' onClick={onPrev}>
											Back
										</Button>
									</Col>
									<Col md='2' className='form-group pt-2'>
										<Button theme='success' type='submit'>
											Review
										</Button>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

SelectQuantity.propTypes = {
	/**SelectDiagnosis
	 * The component's title.
	 */
	title: PropTypes.string,
};

SelectQuantity.defaultProps = {
	title: "Request Product",
};

export default SelectQuantity;
