import { lineHeight } from "@mui/system";
import PropTypes from "prop-types";
import React from "react";

import "react-phone-number-input/style.css";
import { toast, ToastContainer } from "react-toastify";
import {
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";

const SelectProducts = ({
	title,
	handleSubmit,
	onNext,
	onPrev,
	itemsToRequest,
	selectItem,
	deselectItem,
}) => {
	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>{title}</h6>
			</CardHeader>
			<ToastContainer />
			<ListGroup flush>
				<ListGroupItem className='p-3'>
					<Row>
						<Col>
							<Form
								method='post'
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
							>
								<Row form>
									{itemsToRequest.length ? (
										itemsToRequest.map((item) => (
											<Col md='3' className='mb-4'>
												<Card
													small
													className='mb-4'
													style={{ padding: "20px", lineHeight: "30px" }}
												>
													Name: {item.itemName} <br /> Size : {item.size}
													<br />
													Quantity In Stock : {item.remainingQty}
													{!item.remainingQty ? (
														<Button
															style={{ marginTop: "20px" }}
															theme='warning'
															onClick={() => selectItem(item.item)}
															disabled
														>
															Out of stock
														</Button>
													) : !item.selected ? (
														<Button
															style={{ marginTop: "20px" }}
															theme='success'
															onClick={() => selectItem(item.item)}
														>
															Select
														</Button>
													) : (
														<Button
															style={{ marginTop: "20px" }}
															theme='danger'
															onClick={() => deselectItem(item.item)}
														>
															Deselect
														</Button>
													)}
												</Card>
											</Col>
										))
									) : (
										<h5 style={{ padding: "50px 100px" }}>
											No items under selected procedure. please select different
											procedure or contact adminstrators.
										</h5>
									)}

									<Col md='10' className='form-group pt-3'>
										<Button
											theme='info'
											onClick={onPrev}
											className='cust_buttons'
										>
											Back
										</Button>
									</Col>
									<Col md='2' className='form-group pt-2'>
										<Button
											theme='success'
											className='cust_buttons'
											onClick={() => {
												itemsToRequest.find((el) => el.selected === true)
													? onNext()
													: toast.warning("Please select atleast one item.");
											}}
											disabled={!itemsToRequest.length}
										>
											Next
										</Button>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

SelectProducts.propTypes = {
	/**SelectDiagnosis
	 * The component's title.
	 */
	title: PropTypes.string,
};

SelectProducts.defaultProps = {
	title: "Select Products",
};

export default SelectProducts;
