import { apiCall, apiFetch } from "./ApiCall";
const diagnosisApi = {
	add: (data, onSuccess, onFailure) =>
		apiCall("post", data, "/diagnosis/", onSuccess, onFailure),
	getAll: (onSuccess, onFailure) =>
		apiFetch("get", "/diagnosis", onSuccess, onFailure),
	deleteOne: (id, onSuccess, onFailure) =>
		apiFetch("delete", `/diagnosis/${id}`, onSuccess, onFailure),
	updateOne: (data, id, onSuccess, onFailure) =>
		apiCall("put", data, `/diagnosis/${id}`, onSuccess, onFailure),
};

export default diagnosisApi;
