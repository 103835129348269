import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "shards-react";
import suppliersApi from "../api/suppliers";
import PageTitle from "../components/common/PageTitle";
import EditSupplierForm from "../components/SupplierForm/EditSupplierForm";
import { removeKey } from "../utils/deleteKeys";

const EditSuppliers = () => {
	const params = useParams();
	const [supplier, setSupplier] = useState(
		useSelector((state) =>
			state.suppliers.find((supplier) => supplier._id === params.id)
		)
	);

	const setPhoneNumber = (value) => {
		setSupplier((prevState) => ({
			...prevState,
			address: { ...prevState.address, phoneNumber: value },
		}));
	};
	const setEmail = (event) => {
		const { value } = event.target;
		setSupplier((prevState) => ({
			...prevState,
			address: { ...prevState.address, email: value },
		}));
	};

	// use this to update both email and phonenumber
	const handleSetAddress = (event) => {
		const { name, value } = event.target;
		setSupplier((prevState) => ({
			...prevState,
			address: { ...prevState.address, [name]: value },
		}));
	};
	const navigate = useNavigate();

	const handleChange = (event) => {
		const { name, value } = event.target;

		let temp = { ...supplier };
		temp[name] = value;
		setSupplier(temp);
	};
	const handleSubmit = () => {
		toast.promise(
			suppliersApi.updateOne(
				removeKey(supplier),
				supplier._id,
				onSuccess,
				onFailure
			),
			{
				pending: "Saving Product....",
			}
		);
	};

	const onFailure = (error) => {
		console.log("This went Off ", error);
	};

	const onSuccess = () => {
		setTimeout(() => {
			navigate(-1);
		}, 2000);
	};

	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<ToastContainer />
			<Row noGutters className='page-header py-4'>
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to='/admin/suppliers'>Supplier</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>
						<PageTitle
							title=''
							subtitle='Edit Supplier'
							md='12'
							className='ml-sm-auto mr-sm-auto'
						/>
					</BreadcrumbItem>
				</Breadcrumb>
			</Row>

			<Col lg='12'>
				<EditSupplierForm
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					data={supplier}
					setEmail={setEmail}
					setPhoneNumber={setPhoneNumber}
					handleSetAddress={handleSetAddress}
				/>
			</Col>
		</Container>
	);
};

export default EditSuppliers;
