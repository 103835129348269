import MaterialTable from "material-table";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Row,
} from "shards-react";
import proceduresApi from "../api/procedures";
import productApi from "../api/product";
import PageTitle from "../components/common/PageTitle";
import Featching from "../components/Featching/featching";
import FilterModal from "../components/FilterModal/FIlterModal.component";
import UpdateItemQuantity from "../components/UpdateItemQuantity/UpdateItemQuantity.component";
import { formatItme } from "../utils/formatResponse";

const Products = () => {
	const [data, setData] = useState([]);
	const [product, setProduct] = useState(null);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [openFilter, setOpenFilter] = useState(false);
	const dispatch = useDispatch();
	const [fetch, setFetch] = useState(false);
	const navigate = useNavigate();

	const columns = useMemo(
		() => [
			{ title: "Name", field: "itemName" },
			{ title: "Expiry Date", field: "expiryDate" },
			{ title: "Procedure Used In", field: "procedureUsedIn" },
			{ title: "Price", field: "sellingPrice" },
			{
				title: "Stock",
				field: "stock",
				editable: false,
				// render: (rowData) => (
				// 	<Button
				// 		style={{ width: "100%" }}
				// 		theme={
				// 			rowData.stock < 5
				// 				? "danger"
				// 				: rowData.stock < 10
				// 				? "warning"
				// 				: "none"
				// 		}
				// 	>
				// 		{rowData.stock}
				// 	</Button>
				// ),
			},
			// {
			// 	title: "Add to Stock",
			// 	field: "request",
			// 	editable: false,
			// 	render: (rowData) => (
			// 		<Button
			// 			theme='success'
			// 			onClick={() => {
			// 				setProduct(rowData);
			// 				setOpen(true);
			// 			}}
			// 		>
			// 			Add to stock
			// 		</Button>
			// 	),
			// },
		],
		[]
	);

	useEffect(() => {
		async function fetchData() {
			productApi.getAll(
				(items) => {
					proceduresApi.getAll(
						(response) => {
							const procedures = response.data.procedures;
							const formattedItems = formatItme(items.data.items, procedures);
							setData(formattedItems);
							setLoading(false);
						},
						(error) => {
							console.log("this is failure", error);
						}
					);
				},
				() => console.log("this failed")
			);
		}
		fetchData();
	}, [dispatch]);

	useEffect(() => {
		async function fetchData() {}
		fetchData();
	}, []);

	const handleDelete = (id) => {
		productApi.deleteOne(
			id,
			() => {
				toast.success("Item Deleted!");
				setFetch(!fetch);
				setLoading(true);
			},
			() => {
				console.log("This went Off ");
			}
		);
	};

	const onFilter = (filteredData) => {
		// setData(formatItem(filteredData, procedures));
	};

	return (
		<Container fluid className='main-content-container px-4'>
			<Row no Gutters className='page-header py-4'>
				<PageTitle sm='4' title='Products' className='text-sm-left' />
			</Row>
			<FilterModal
				onFilter={onFilter}
				setLoading={setLoading}
				open={openFilter}
				toggle={() => setOpenFilter(!openFilter)}
			/>
			<ToastContainer />

			<Row>
				<Col>
					<Card small className='mb-4 overflow-hidden'>
						<CardHeader
							className='bg-dark'
							style={{
								display: "flex",
								justifyContent: "space-between",
								zIndex: 99999999999999999,
							}}
						>
							<h6 className='m-0 text-white'>All Products</h6>
							<div>
								<Button
									theme='success'
									style={{ marginRight: "10px" }}
									onClick={() => setOpenFilter(!openFilter)}
								>
									<span style={{ marginRight: "10px" }}>
										<i className='material-icons'>sort</i>
									</span>
									Filter
								</Button>
								<Button
									theme='success'
									style={{ marginRight: "10px" }}
									onClick={() => {
										navigate("/admin/register-product");
									}}
								>
									<span style={{ marginRight: "10px" }}>
										<i className='material-icons'>add</i>
									</span>
									Add New Product
								</Button>
								<Button
									theme='success'
									onClick={() => {
										navigate("/admin/upload-product");
									}}
								>
									<span style={{ marginRight: "10px" }}>
										<i className='material-icons'>folder</i>
									</span>
									Upload CVS
								</Button>
							</div>
						</CardHeader>
						<CardBody className='bg-light p-0 pb-0'>
							<MaterialTable
								title={""}
								options={{
									exportButton: true,
									actionsColumnIndex: -1,
									search: true,
								}}
								columns={columns}
								data={data}
								editable={{
									onRowDelete: (oldData) =>
										new Promise((resolve, reject) => {
											setTimeout(() => {
												handleDelete(oldData._id);
												resolve();
											}, 1000);
										}),
								}}
								actions={[
									{
										icon: "book",
										tooltip: "View Detail",
										onClick: (event, rowData) => {
											navigate(`/admin/product-detail/${rowData._id}`);
										},
									},
									{
										icon: "edit",
										tooltip: "Edit Product",
										onClick: (event, rowData) => {
											navigate(`/admin/edit-product/${rowData._id}`);
										},
									},
								]}
							/>
							{loading && <Featching />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Products;
