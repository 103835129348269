import { cropDate } from "./crop-date";
import { formatAMPM } from "./formatAMPM";

export const formatItme = (item, procedures) => {
	let temp = [];

	item.map((product) =>
		temp.push({
			_id: product._id,
			itemName: product.itemName,
			expiryDate: product.expiryDate.toString().substring(0, 10),
			sellingPrice: product.sellingPrice.amount,
			stock: product.statusInStock,
			procedureUsedIn: getProceduresUsedIn(
				procedures,
				product.proceduresUsedIn
			),
		})
	);
	return temp;
};

export const formatSupplier = (supplier) => {
	let temp = [];
	supplier.map((supp) =>
		temp.push({
			_id: supp._id,
			name: supp.supplierName,
			email: supp.address.email,
			phoneNumber: supp.address.phoneNumber,
			country: supp.country,
		})
	);
	return temp;
};

export const formatCategory = (category) => {
	let temp = [];
	category.map((category) =>
		temp.push({
			_id: category._id,
			name: category.categoryName,
			description: category.description,
		})
	);
	return temp;
};

export const formatUsers = (users) => {
	let temp = [];
	users.map((user) =>
		temp.push({
			_id: user._id,
			name: user.firstName + " " + user.lastName,
			email: user.email,
			phoneNumber: user.phoneNumber,
		})
	);
	return temp;
};

// export const formatOrder = (data) => {
// 	let temp = [];

// 	data.map((data) =>
// 		temp.push({
// 			name: data.patientName,
// 			procedureUsedIn: data.procedureUsedIn,
// 			age: data.age,
// 			sex: data.sex,
// 			diagnosis: data.diagnosis,
// 			date: data.date.substring(0, 10),
// 			time: data.time,
// 			items: data.items,
// 		})
// 	);
// 	return temp;
// };

export const formatAppointments = (appointments) => {
	let temp = [];

	appointments.map((appt) =>
		temp.push({
			patientName: `${appt.patientFirstName} ${appt.patientLastName} `,
			apptDate: cropDate(appt.appointmentDate),
			apptTime: formatAMPM(
				appt.appointmentTime.hour,
				appt.appointmentTime.minute
			),
			reason: appt.reasonForVisit,
			_id: appt._id,
			date: appt.appointmentDate,
		})
	);
	return temp;
};

export const formatOrders = (orders, items, procedures, diagnosis) => {
	let temp = [];

	orders.map((order) =>
		temp.push({
			patientName: order.patientName,
			date: cropDate(order.orderDate),
			time: order.orderTime,
			procedureUsedIn: getProcedure(order.procedure, procedures),
			status: order.status,
			_id: order._id,
			sex: order.sex,
			items: getOrderdItems(order.orderedItems, items),
			diagnosis: getDiagnosis(order.diagnosis, diagnosis),
			price: order.totalPrice,
			procedurePrice: order.procedurePrice,
			appointmentId: order.appointmentId,
		})
	);

	return temp;
};

const getOrderdItems = (orders, items) => {
	let list = "";
	items &&
		orders.map((el) => {
			let item = items.find((item) => item._id === el.item);
			if (item) {
				list = list.length ? list + " , " + item.itemName : item.itemName;
			}
		});
	return list;
};

const getProcedure = (id, procedure) => {
	let result = procedure.find((item) => item._id === id);

	return result && result.procedureName;
};

const getDiagnosis = (id, diagnosis) => {
	let result = diagnosis.find((item) => item._id === id);

	return result && result.diagnosisName;
};

const getProceduresUsedIn = (procedures, proceduresUsedIn) => {
	let list = "";
	procedures &&
		proceduresUsedIn &&
		proceduresUsedIn.map((el) => {
			let procedure = procedures.find((procedur) => procedur._id === el);
			if (procedure) {
				list = list.length
					? list + " , " + procedure.procedureName
					: procedure.procedureName;
			}
		});
	return list;
};

// procedures.find(
// 	(procedure) => procedure._id === product.procedureUsedIn
// )
// 	? procedures.find(
// 			(procedure) => procedure._id === product.procedureUsedIn
// 	  ).procedureName
// 	: "",
