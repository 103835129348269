import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "shards-react";

import PageTitle from "../components/common/PageTitle";

import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import userApi from "../api/user";
import UserProfileDetails from "../components/UserProfile/UserProfileDetails";
import { userSignedIn } from "../store/user-reducer";
const UserProfile = () => {
	const user = useSelector((state) => state.user[0]);
	const [tempUser, setTempUser] = useState(user);
	const dispatch = useDispatch();

	const handleSubmit = () => {
		toast.promise(
			userApi.update(
				{ ...tempUser },
				sessionStorage.getItem("userId"),
				onSuccess,
				onFailure
			),
			{
				pending: "proccessing ...",
			}
		);
	};

	useEffect(() => {
		userApi.getOne(
			sessionStorage.getItem("userId"),
			(data) => {
				dispatch(userSignedIn(data.data.user));
				setTempUser(data.data.user);
			},
			onFailure
		);
	}, []);

	const onSuccess = () => {
		userApi.getOne(
			sessionStorage.getItem("userId"),
			(data) => {
				setTempUser(data.data.user);
			},
			onFailure
		);
	};
	const onFailure = (error) => {
		console.log("This went Off  ", error);
	};

	return (
		<Container fluid className='main-content-container px-4'>
			<Row noGutters className='page-header py-4'>
				<PageTitle
					title='User Profile'
					subtitle='Overview'
					md='12'
					className='ml-sm-auto mr-sm-auto'
				/>
			</Row>
			<ToastContainer />
			<Row>
				{/* <Col lg='4'>
					<UserDetails userDetails={tempUser} setUser={setTempUser} />
				</Col> */}
				<Col lg='12'>
					<UserProfileDetails
						user={tempUser}
						setUser={setTempUser}
						handleSubmit={handleSubmit}
					/>
				</Col>
			</Row>
		</Container>
	);
};
export default UserProfile;
