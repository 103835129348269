export const getItesForProcedure = (items, procedureId) => {
	const temp = [];

	let filterd = items.filter((item) =>
		item.proceduresUsedIn.join().includes(procedureId)
	);

	filterd.map((item) =>
		temp.push({
			item: item._id,
			itemName: item.itemName,
			quantity: 1,
			price: item.sellingPrice,
			size: item.size,
			remainingQty: item.remainingQty,
			selected: false,
		})
	);
	return temp;
};
