import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "shards-react";
import productApi from "../api/product";
import PageTitle from "../components/common/PageTitle";

import ProductForm from "../components/ProductForm/AddProductForm";

const RegisterProduct = () => {
	const [data, setData] = useState({
		itemName: "",
		purchasePrice: {
			currency: "ETB",
			amount: 0,
		},
		category: "",
		supplier: "",
		serialNumber: "",
		description: "",
		deliveryDate: "",
		sellingPrice: {
			currency: "ETB",
			amount: 0,
		},
		deliveredQty: "",
		expiryDate: "",
		proceduresUsedIn: "",
		size: "",
	});
	const [currency, setCurrency] = useState("ETB");
	const navigate = useNavigate();

	const handleChange = (event) => {
		const { name, value } = event.target;

		let temp = { ...data };
		if (name === "purchasePrice") {
			temp.purchasePrice = {
				currency: currency,
				amount: parseInt(value),
			};
		} else if (name === "sellingPrice") {
			temp.sellingPrice = {
				currency: currency,
				amount: parseInt(value),
			};
		} else {
			temp[name] = value;
		}
		// temp.purchasePrice.currency = currency;
		// temp.sellingPrice.currency = currency;

		setData(temp);
	};
	const handleSubmit = () => {
		toast.promise(productApi.add(data, onSuccess, onFailure), {
			pending: "adding Product....",
		});
	};

	const onFailure = (error) => {
		console.log("This went Off ", error);
	};
	const onSuccess = () => {
		setTimeout(() => {
			navigate(-1);
		}, 2000);
	};
	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<ToastContainer />
			<Row noGutters className='page-header py-4'>
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to='/admin/products'>Products</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>
						<PageTitle
							title=''
							subtitle='Register Product'
							md='12'
							className='ml-sm-auto mr-sm-auto'
						/>
					</BreadcrumbItem>
				</Breadcrumb>
			</Row>

			<Col lg='12'>
				<ProductForm
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					state={data}
					phone={data.phone}
					setCurrency={setCurrency}
					currency={currency}
				/>
			</Col>
		</Container>
	);
};

export default RegisterProduct;
