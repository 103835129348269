import React, { useEffect, useState } from "react";
import {
	Button,
	FormSelect,
	InputGroup,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "shards-react";
import diagnosisApi from "../../api/diagnosis";
import proceduresApi from "../../api/procedures";
import productApi from "../../api/product";
import { getItesForProcedure } from "../../utils/getItemsForProcedure";
import "./OrderItems.styles.css";
const OrderItemsModal = ({ toggle, open, patient }) => {
	const [items, setItems] = useState([]);
	const [diagnosis, setDiagnosis] = useState([]);
	const [procedures, setProcedures] = useState([]);
	const [selectedProcedure, setSelectedProcedure] = useState("");
	const [selectedDiagnosis, setSelectedDiagnosis] = useState("");
	const [itemsToRequest, setItemsToRequest] = useState([]);
	const [request, setRequest] = useState({
		orderedItems: itemsToRequest,
		diagnosis: "000000000",
		phoneNumber: "9786876",
		age: 30,
		sex: "male",
		orderTime: "09:30 am",
	});

	useEffect(() => {
		productApi.getAll(onProductSuccess, onFaliure);
		proceduresApi.getAll(onProcedureuccess, onFaliure);
		diagnosisApi.getAll(onDiagnosisSuccess, onFaliure);
	}, []);

	const onProductSuccess = (resp) => {
		setItems(resp.data.items);
	};
	const onDiagnosisSuccess = (resp) => {
		setDiagnosis(resp.data.allDiagnosis);
	};

	const onProcedureuccess = (resp) => {
		setProcedures(resp.data.procedures);
	};

	useEffect(() => {
		setItemsToRequest(getItesForProcedure(items, selectedProcedure));

		setRequest({
			...request,
			orderedItems: getItesForProcedure(items, selectedProcedure),
			diagnosis: selectedDiagnosis,
		});
	}, [selectedProcedure]);

	const handleAdd = (e) => {
		e.preventDefault();

		productApi.requestProduct(
			{
				...request,
				orderDate: patient.date,
				patientName: patient.patientName,
				procedure: selectedProcedure,
			},
			onSuccess,
			onFaliure
		);
	};

	const onSuccess = (res) => {
		toggle();
	};
	const onFaliure = (error) => {
		console.log("This went Off", error);
	};

	const handleChangeInItemQuantity = (e) => {
		let temp = [...request.orderedItems];

		let index = temp.findIndex((el) => el.item === e.target.name);

		let removed = temp.splice(index, 1);

		temp.push({
			...removed[0],
			quantity: parseInt(e.target.value),
		});

		setRequest({
			...request,
			orderedItems: temp,
		});
	};

	return (
		<Modal
			open={open}
			toggle={toggle}
			centered={true}
			size='md'
			style={{
				zIndex: "99999",
			}}
		>
			<ModalHeader>Request item for {patient.patientName}</ModalHeader>

			<form onSubmit={handleAdd}>
				<ModalBody style={{ height: "fit-content" }}>
					<InputGroup className='time-modal'>
						<label htmlFor='start' style={{ padding: "10px 20px" }}>
							Diagnosis
						</label>
						<FormSelect
							placeholder='time'
							type='time'
							className='time-selector'
							id='start'
							required
							value={selectedProcedure}
							onChange={(e) => {
								setSelectedDiagnosis(e.target.value);
							}}
						>
							<option value='' disabled>
								{" "}
								Select Diagnosis
							</option>
							{diagnosis.map((procedure) => (
								<option value={procedure._id}>{procedure.diagnosisName}</option>
							))}
						</FormSelect>
					</InputGroup>
					<InputGroup className='time-modal'>
						<label htmlFor='start' style={{ padding: "10px 20px" }}>
							Procedure
						</label>
						<FormSelect
							placeholder='time'
							type='time'
							className='time-selector'
							id='start'
							required
							value={selectedProcedure}
							onChange={(e) => setSelectedProcedure(e.target.value)}
						>
							<option value='' disabled>
								{" "}
								Select Procedure
							</option>
							{procedures.map((procedure) => (
								<option value={procedure._id}>{procedure.procedureName}</option>
							))}
						</FormSelect>
					</InputGroup>

					{itemsToRequest &&
						itemsToRequest.map((item) => (
							<InputGroup className='time-modal'>
								<label htmlFor='start' style={{ padding: "10px 20px" }}>
									{item.itemName} Quantity :
								</label>
								<input
									placeholder='Quantity'
									type='number'
									min={1}
									name={item.item}
									className='time-selector form-control custom-select'
									required
									onChange={handleChangeInItemQuantity}
								/>
							</InputGroup>
						))}
				</ModalBody>
				<ModalFooter>
					<Button
						theme='success'
						style={{ zIndex: "99999999999" }}
						type='submit'
						disabled={!itemsToRequest.length}
					>
						Add
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default OrderItemsModal;
