import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Row,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";

import MaterialTable from "material-table";
import userApi from "../api/user";
import { usersAdded } from "../store/users-reducer";
import { formatUsers } from "../utils/formatResponse";
import Featching from "../components/Featching/featching";

const Users = () => {
	const [data, setData] = useState([]);
	const columns = [
		{ title: "Name", field: "name" },
		{
			title: "Email",
			field: "email",
		},
		{ title: "Phone Number", field: "phoneNumber" },
	];
	const dispatch = useDispatch();
	const [fetch, setFetch] = useState(false);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	useEffect(() => {
		userApi.getAll(onSuccess, onFailure);
		// eslint-disable-next-line
	}, [fetch]);

	const handleDelete = (id) => {
		userApi.deleteOne(
			id,
			() => {
				toast.success("User Deleted!");
				setFetch(!fetch);
			},
			onFailure
		);
	};

	const onSuccess = (data) => {
		dispatch(usersAdded(data.data.users));
		setData(formatUsers(data.data.users));
		setLoading(false);
	};
	const onFailure = (error) => {
		dispatch(usersAdded([]));

		console.log("This went Off ", error);
	};

	return (
		<Container fluid className='main-content-container px-4'>
			{/* Page Header */}
			<Row noGutters className='page-header py-4'>
				<PageTitle sm='4' title='Users' className='text-sm-left' />
			</Row>
			<ToastContainer />

			<Row>
				<Col>
					<Card small className='mb-4 overflow-hidden'>
						<CardHeader
							className='bg-dark'
							style={{
								display: "flex",
								justifyContent: "space-between",
								zIndex: 99,
							}}
						>
							<h6 className='m-0 text-white'>All Users</h6>
							<Button
								theme='success'
								onClick={() => {
									navigate("/user/register");
								}}
							>
								<span style={{ marginRight: "10px" }}>
									<i className='material-icons'>add</i>
								</span>
								Add New User
							</Button>
						</CardHeader>
						<CardBody className='bg-light p-0 '>
							<MaterialTable
								title={""}
								options={{
									exportButton: true,
									actionsColumnIndex: -1,
									search: true,
								}}
								columns={columns}
								data={data}
								editable={{
									onRowDelete: (oldData) =>
										new Promise((resolve, reject) => {
											setTimeout(() => {
												handleDelete(oldData._id);
												resolve();
											}, 1000);
										}),
								}}
							/>
							{loading && <Featching />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Users;
