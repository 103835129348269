import PropTypes from "prop-types";
import React from "react";

import "react-phone-number-input/style.css";
import {
	Button,
	Card,
	CardHeader,
	Col,
	Form,
	FormInput,
	FormTextarea,
	ListGroup,
	ListGroupItem,
	Row,
} from "shards-react";

const CategoryForm = ({ title, handleChange, handleSubmit }) => {
	return (
		<Card small className='mb-4'>
			<CardHeader className='border-bottom'>
				<h6 className='m-0'>{title}</h6>
			</CardHeader>
			<ListGroup flush>
				<ListGroupItem className='p-3'>
					<Row>
						<Col>
							<Form
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
								method='post'
							>
								<Row form>
									{/* First Name */}
									<Col md='6' className='form-group'>
										<label htmlFor='feProductName'>Category Name</label>
										<FormInput
											id='feProductName'
											placeholder='Category Name'
											required
											name='categoryName'
											onChange={handleChange}
										/>
									</Col>

									<Col md='6' className='form-group'>
										<label htmlFor='feTextArea'>Description</label>
										<FormTextarea
											type='textarea'
											id='feTextArea'
											placeholder='Description'
											required
											name='description'
											onChange={handleChange}
										/>
									</Col>
									{/* Password */}
								</Row>

								<Button theme='accent' type='submit'>
									Save Category
								</Button>
							</Form>
						</Col>
					</Row>
				</ListGroupItem>
			</ListGroup>
		</Card>
	);
};

CategoryForm.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

CategoryForm.defaultProps = {
	title: "Category Details",
};

export default CategoryForm;
