import axios from "axios";
import { ENDPOINT } from "./Api";

import { toast } from "react-toastify";

export const apiCall = async (
	method,
	data,
	path,
	callback,
	onFailure,
	token
) => {
	try {
		console.log("this is request", method, data, path);

		const config = {
			headers: {
				Authorization: `Bearer ${token ?? sessionStorage.getItem("token")}`,
			},
		};

		const response = await axios[method](`${ENDPOINT}${path}`, data, config);

		console.log(response, "this is the response");
		callback(response.data);
		toast.success(response.data.message);
		return response.data;
	} catch (error) {
		onFailure(error);
		const errorData = error.response.data;

		if (errorData.message) {
			toast.error(errorData.message);
		} else if (errorData.errors) {
			Object.values(errorData.errors).forEach((errorMessage) => {
				toast.error(errorMessage);
			});
		}

		return Promise.reject(errorData);
	}
};

export const apiFetch = (method, path, callback, onFailure) =>
	new Promise((resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("token")}`,
			},
		};

		axios[method](`${ENDPOINT}${path}`, config)
			.then((res) => {
				resolve();
				callback(res.data);
			})
			.catch((error) => {
				reject();
				onFailure(error);
			});
	});

export const apiDelete = (method, path, callback, onFailure) =>
	new Promise((resolve, reject) => {
		const config = {
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem("token")}`,
			},
		};

		axios["delete"](`${ENDPOINT}${path}`, config)
			.then((res) => {
				resolve();
				callback(res.data);
			})
			.catch((error) => {
				reject();
				onFailure(error);
			});
	});
