import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from "shards-react";
import proceduresApi from "../api/procedures";
import productApi from "../api/product";
import PageTitle from "../components/common/PageTitle";

import RequestProduct from "../components/ProductForm/RequestProduct";
import HorizontalLinearStepper from "../components/Stepper/Stepper.component";
import { ordersAdded } from "../store/orders-reducer";
import { proceduresAdded } from "../store/procedure-reducer";

const RequestItem = () => {
	const [data, setData] = useState({
		patientName: "",
		procedure: "",
		age: "",
		sex: "",
		diagnosis: "",
		date: "",
		time: "",
	});

	const [products, setProducts] = useState([]);
	const [procedures, setProcedures] = useState([]);

	useEffect(() => {
		productApi.getAll(
			(data) => setProducts(data.data.items, "items"),
			onFailure
		);
		proceduresApi.getAll(procedureOnSuccess, onFailure);

		// eslint-disable-next-line
	}, []);

	const procedureOnSuccess = (data) => {
		dispatch(proceduresAdded(data.data.procedures));
		setProcedures(data.data.procedures);
	};

	const onFailure = (error) => {
		console.log("This went Off ", error);
	};
	const dispatch = useDispatch();

	const handleChange = (event) => {
		const { name, value } = event.target;

		let temp = { ...data };
		temp[name] = value;
		setData(temp);
	};
	const handleSubmit = (e) => {
		let itemsRequierd = products.filter(
			(item) => item.procedureUsedIn === data.procedureUsedIn
		);

		let names = itemsRequierd.map((a) => a.itemName);

		let procedure = procedures.find(
			(prod) => prod._id === data.procedureUsedIn
		).procedureName;

		dispatch(
			ordersAdded({ ...data, items: String(names), procedureUsedIn: procedure })
		);
		toast.success("Item Requested Succesfuly");
		e.target.reset();
	};

	return (
		<Container fluid className='main-content-container px-4 pb-4'>
			<ToastContainer />
			<Row noGutters className='page-header py-4'>
				<Breadcrumb>
					<BreadcrumbItem>
						<Link to='/admin/appointments'>Appointments</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>
						<PageTitle
							title=''
							subtitle='Request Items'
							md='12'
							className='ml-sm-auto mr-sm-auto'
						/>
					</BreadcrumbItem>
				</Breadcrumb>
			</Row>

			<Col lg='12'>
				<HorizontalLinearStepper
					handleChange={handleChange}
					handleSubmit={handleSubmit}
					state={data}
					phone={data.phone}
				/>
			</Col>
		</Container>
	);
};

export default RequestItem;
