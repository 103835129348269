import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Row,
} from "shards-react";
import proceduresApi from "../api/procedures";
import PageTitle from "../components/common/PageTitle";
import { proceduresAdded } from "../store/procedure-reducer";

import MaterialTable from "material-table";
import React from "react";
import { productsAdded } from "../store/products-reducer";

import appointmentsApi from "../api/Appointments";
import Featching from "../components/Featching/featching";
import { formatAppointments } from "../utils/formatResponse";
import OrderItemsModal from "../components/OrderItems/OrderItems.component";

const Appointments = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [modal, setModal] = useState(false);
	const [patient, setpatient] = useState({});
	const columns = [
		{ title: "Patient Name", field: "patientName" },
		{
			title: "Appt. Date",
			field: "apptDate",
		},
		{
			title: "Appt. Time",
			field: "apptTime",
			// width: "100%",
		},
		{ title: "Reason for Visit", field: "reason" },

		{
			title: "Request Products",
			field: "request",
			editable: false,
			render: (rowData) => (
				<Button
					theme='success'
					onClick={() => {
						navigate(`/admin/request-items/${rowData._id}`);
						setpatient(rowData);
						// setLoading(true);
					}}
				>
					Request
				</Button>
			),
		},
	];

	const dispatch = useDispatch();

	const navigate = useNavigate();

	useEffect(() => {
		appointmentsApi.getAll(onSuccess, onFailure);
		// eslint-disable-next-line
	}, []);

	const onSuccess = (resp) => {
		setData(formatAppointments(resp.data.appointments));
		proceduresApi.getAll(procedureSuccess, onFailure);
	};
	const procedureSuccess = (data) => {
		setLoading(false);
		dispatch(proceduresAdded(data.data.procedures));
		// setData(formatItme(products, procedures));
	};
	const onFailure = (error) => {
		dispatch(productsAdded([]));

		console.log("This went Off ", error);
	};

	return (
		<Container fluid className='main-content-container px-4'>
			{/* Page Header */}
			<Row noGutters className='page-header py-4'>
				<PageTitle sm='4' title='Appointments' className='text-sm-left' />
			</Row>
			<ToastContainer />
			<OrderItemsModal
				open={modal}
				toggle={() => setModal(!modal)}
				patient={patient}
			/>
			<Row>
				<Col>
					<Card small className='mb-4 overflow-hidden'>
						<CardHeader
							className='bg-dark'
							style={{
								display: "flex",
								justifyContent: "space-between",
								zIndex: 99,
							}}
						>
							<h6 className='m-0 text-white'>All Appointments</h6>
						</CardHeader>
						<CardBody className='bg-light p-0 pb-0'>
							<MaterialTable
								title={""}
								options={{
									exportButton: true,
									actionsColumnIndex: -1,
									search: true,
								}}
								columns={columns}
								data={data}
								// actions={[
								// 	{
								// 		icon: "check",
								// 		tooltip: "Request Items",
								// 		onClick: (event, rowData) => {
								// 			// navigate(`/admin/product-detail/${rowData._id}`);
								// 			setModal(!modal);
								// 			setpatient(rowData);
								// 		},
								// 	},
								// ]}
							/>
							{loading && <Featching />}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Appointments;
